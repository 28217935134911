import React, { createContext, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Ensure you have uuid installed: npm install uuid
import APIChatDB from './db/APIChatDb'; // Adjust the import path as necessary
import { LsIcons } from "@/components/ui/LsIcons";

const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

// Initial menu items without JSX elements
const initialMenuItems = [
    { path: "/", label: "Home", canHaveChild: false, icon: "home" },
    { path: "/chat-stock", label: "Chat stock", canHaveChild: false, icon: "chat-stock" },
    { path: "/playground", label: "LLM playground", canHaveChild: true, icon: "playground" },
    { path: "/text2image", label: "Text2Image", canHaveChild: true, icon: "text2image" },
    { path: "/text2video", label: "Text2Video", canHaveChild: true, icon: "text2video" },
    { path: "/aitools", label: "AI-Tools", canHaveChild: false, icon: "aitools" },
    { path: "/blog", label: "Blogs", canHaveChild: false, icon: "blog" },
    { path: "/specialoffer", label: "Special offer", canHaveChild: false, icon: "specialoffer" },
    { path: "/market", label: "Market", canHaveChild: false, icon: "market" },
    { path: "/dashboards", label: "Dashboards", canHaveChild: false, icon: "dashboards" },
    { path: "/settings", label: "Settings", canHaveChild: false, icon: "settings" },
    { path: "/about", label: "About", canHaveChild: false, icon: "about" },
];

// Mapping of icons
const iconMapping = {
    "home": LsIcons.Menu_home_svg_icon,
    "chat-stock": LsIcons.Menu_chatstock_svg_icon,
    "playground": LsIcons.Menu_playground_svg_icon,
    "market": LsIcons.Menu_market_svg_icon,
    "dashboards": LsIcons.Menu_dashboards_svg_icon,
    "settings": LsIcons.Menu_settings_svg_icon,
    "about": LsIcons.Menu_about_svg_icon,
    "text2image":LsIcons.Tetx2Image_svg_icon,
    "aitools":LsIcons.AI_Tools_svg_icon,
    "blog": LsIcons.Blog_svg_icon,
    "text2video": LsIcons.Video_svg_icon,
    "specialoffer": LsIcons.Heart_svg_icon,
};

const addIconsToMenuItems = (items) => {
    return items.map(item => ({
        ...item,
        icon: iconMapping[item.icon],
        children: item.children ? addIconsToMenuItems(item.children) : []
    }));
};

const loadSubmenus = (menuItems) => {
    try {
        const savedSubmenus = localStorage.getItem('submenus');
        if (savedSubmenus) {
            const parsedSubmenus = JSON.parse(savedSubmenus);
            return menuItems.map((item, index) => ({
                ...item,
                children: parsedSubmenus[index] || []
            }));
        }
    } catch (error) {
        console.error("Failed to load submenus from localStorage:", error);
    }
    return menuItems;
};

const saveSettings = async (newChatId, oldChatId) => {
    try {
        const oldSettings = await APIChatDB.settings.where('sessionId').equals(oldChatId).toArray();
        const newSettings = oldSettings.map(setting => ({ ...setting, sessionId: newChatId }));
        await APIChatDB.settings.bulkPut(newSettings);
    } catch (error) {
        console.error("Failed to save new session settings:", error);
        if (error.failures) {
            error.failures.forEach(failure => {
                console.error("Failed operation:", failure);
            });
        }
    }
};

// 修改 generateChatName 函数以接受旧菜单名称参数
const generateChatName = (oldLabel) => {
    return `f ${oldLabel}`;
};

export const MenuProvider = ({ children }) => {

    const [menuItems, setMenuItems] = useState(() => {
        const itemsWithIcons = addIconsToMenuItems(initialMenuItems);
        return loadSubmenus(itemsWithIcons);
    });

    const updateLocalStorage = (items) => {
        const submenus = items.map(item => item.children || []);
        localStorage.setItem('submenus', JSON.stringify(submenus));
    };

    useEffect(() => {
        updateLocalStorage(menuItems);
    }, [menuItems]);

    const addSubMenu = (index, subMenuName, path) => {
        const newMenuItems = [...menuItems];
        if (!newMenuItems[index].children) {
            newMenuItems[index].children = [];
        }
        newMenuItems[index].children.push({ path, label: subMenuName });
        setMenuItems(newMenuItems);
        updateLocalStorage(newMenuItems);
    };

    const removeSubMenu = async (parentIndex, subIndex) => {
        const newMenuItems = [...menuItems];
        const subMenuToRemove = newMenuItems[parentIndex].children[subIndex];
        const sessionId = subMenuToRemove.path.split('/').pop(); // Extract session ID from path
        newMenuItems[parentIndex].children.splice(subIndex, 1);
        setMenuItems(newMenuItems);
        updateLocalStorage(newMenuItems);

        // Remove session settings and messages from the database
        try {
            await APIChatDB.settings.where('sessionId').equals(sessionId).delete();
            await APIChatDB.messages.where('sessionId').equals(sessionId).delete();
        } catch (error) {
            console.error("Failed to delete session records from the database:", error);
        }
    };

    const renameSubMenu = (parentIndex, subIndex, newLabel) => {
        const newMenuItems = [...menuItems];
        newMenuItems[parentIndex].children[subIndex].label = newLabel;
        setMenuItems(newMenuItems);
        updateLocalStorage(newMenuItems);
    };

    const forkMenu = async (parentIndex, subIndex) => {
        const oldMenuItem = menuItems[parentIndex].children[subIndex];
        const oldChatId = oldMenuItem.path.split('/').pop(); // Extract the old chat ID
        const newChatId = uuidv4();
        const newChatUrl = `/chat-session/${newChatId}`;
        const chatName = generateChatName(oldMenuItem.label); // 使用旧菜单名称生成新的聊天名称

        addSubMenu(parentIndex, chatName, newChatUrl);

        await saveSettings(newChatId, oldChatId);

        // Perform navigation using window.location after async operations are complete
        window.location.href = newChatUrl;
    };

    return (
        <MenuContext.Provider value={{ menuItems, addSubMenu, removeSubMenu, renameSubMenu, forkMenu }}>
            {children}
        </MenuContext.Provider>
    );
};
