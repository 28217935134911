import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ImageCard from './ImageCard';
import ExampleImageCard from './ExampleImageCard';
import SettingsDialog from './SettingsDialog';
import '../css/Text2Image.css';
import * as Tabs from '@radix-ui/react-tabs';
import * as RadioGroup from '@radix-ui/react-radio-group';
import exampleStaticData from '../data/example.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LsIcons } from './ui/LsIcons';
import FormData from 'form-data';
import Dexie from 'dexie';
import { ChatOpenAI } from "@langchain/openai";
import { HumanMessage } from "@langchain/core/messages";
import { useLocation } from 'react-router-dom';
import * as Tooltip from '@radix-ui/react-tooltip';
import "../css/tooltip.css"
import * as fal from "@fal-ai/serverless-client";
import ReactGA from "react-ga4";
import SEO from './seo/SEO';
import OpenAI from "openai";
import FeedAdComponent from './FeedAdComponent';

const MAX_SEED = 4294967294;
const IMAGES_PER_PAGE = 36;

const Text2Image = () => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "text2image" });
    const [prompt, setPrompt] = useState('');
    const [images, setImages] = useState([]);
    const [showSettings, setShowSettings] = useState(false);
    const [activeTab, setActiveTab] = useState('example');
    const [isTranslating, setIsTranslating] = useState(false);
    const promptContainerRef = useRef(null);
    const blobUrlsRef = useRef(new Map());
    const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;
    const [exampleData, setExampleData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const stability_setting = {
        url: 'https://api.stability.ai/v2beta/stable-image/generate/sd3',
        apikey: '',
        image_size: '1024x1024',
        aspect_ratio: '1:1',
        num_inference_steps: 28,
        guidance_scale: 7.5,
        negative_prompt: 'nsfw',
        seed: '',
    }

    const fal_setting = {
        // url: 'fal-ai/flux/dev',
        url: 'fal-ai/flux-pro/v1.1',
        apikey: process.env.REACT_APP_FAL_KEY,
        image_size: 'square_hd',
        num_inference_steps: 28,
        negative_prompt: 'nsfw',
        seed: '',
      };

    const siliconflow_setting = {
        url: 'https://api.siliconflow.cn/v1/stabilityai/stable-diffusion-3-medium/text-to-image',
        apikey: '',
        image_size: '1024x1024',
        aspect_ratio: '1:1',
        num_inference_steps: 28,
        guidance_scale: 7.5,
        negative_prompt: 'nsfw',
        seed: '',
    }

    useEffect(() => {
        fetchCategories();
    }, []);
    useEffect(() => {
        fetchExampleData();
    }, [selectedCategory, page]);

    const fetchCategories = async () => {
        try {
            const query = `
                query {
                    imagecategories {
                        id
                        name
                    }
                }
            `;
            const response = await axios.post(`${apiUrl}/graphql`, { query });
            const fetchedCategories = response.data.data.imagecategories;
            setCategories([...fetchedCategories, { id: 'buildin', name: 'Built-in' }]);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchExampleData = async () => {
        try {
            if (selectedCategory === 'buildin') {
                setExampleData(exampleStaticData);
                setTotalPages(1);
            } else {
                let query;
                let variables;

                if (selectedCategory === 'all') {
                    query = `
                    query($start: Int!, $limit: Int!) {
                        t2Iexamples(start: $start, limit: $limit, sort: "id:DESC") {
                            id
                            prompt
                            img {
                                url
                            }
                        }
                        t2IexamplesConnection {
                            aggregate {
                                count
                            }
                        }
                    }
                `;
                    variables = {
                        start: (page - 1) * IMAGES_PER_PAGE,
                        limit: IMAGES_PER_PAGE,
                    };
                } else {
                    query = `
                    query($start: Int!, $limit: Int!, $category: ID!) {
                        t2Iexamples(
                            where: { imagecategory: { id: $category } }
                            start: $start
                            limit: $limit
                            sort: "id:DESC"
                        ) {
                            id
                            prompt
                            img {
                                url
                            }
                        }
                        t2IexamplesConnection(where: { imagecategory: { id: $category } }) {
                            aggregate {
                                count
                            }
                        }
                    }
                `;
                    variables = {
                        start: (page - 1) * IMAGES_PER_PAGE,
                        limit: IMAGES_PER_PAGE,
                        category: selectedCategory,
                    };
                }

                const response = await axios.post(`${apiUrl}/graphql`, { query, variables });
                const fetchedData = response.data.data.t2Iexamples.map(example => ({
                    prompt: example.prompt,
                    url: `${apiUrl}${example.img[0].url}`,
                }));
                setExampleData(fetchedData);
                const totalCount = response.data.data.t2IexamplesConnection.aggregate.count;
                setTotalPages(Math.ceil(totalCount / IMAGES_PER_PAGE));
            }
        } catch (error) {
            console.error('Error fetching example data:', error);
            setExampleData([]);
            setTotalPages(0);
        }
    };


    const isChineseLanguage = () => {
        const language = navigator.language || navigator.userLanguage;

        let ret = /^zh/i.test(language);
        // console.log("language ", language, ret);
        return ret;
    };

    const [settings, setSettings] = useState(isChineseLanguage() ? siliconflow_setting : fal_setting);

    const [promptRefinerSettings, setPromptRefinerSettings] = useState({
        provider: 'groq',
        base_url: 'https://api.groq.com/openai/v1',
        apiKey: '',
        model: 'gemma2-9b-it',
        available_models: "",
    });

    const isStabilityAI = settings.url.includes('stability.ai');
    const [settingsTab, setSettingsTab] = useState('text2image');

    // Initialize Dexie database
    const db = new Dexie('text2image-db');
    db.version(1).stores({
        images: '++id,prompt,negative_prompt,seed,steps,guidance_scale,image,image_url'
    });

    useEffect(() => {
        let text2image_url = '';
        let text2image_apikey = '';
        const savedSettings = localStorage.getItem('text2image-settings');
        if (savedSettings) {
            const parsedSettings = JSON.parse(savedSettings);
            text2image_url = parsedSettings.url;
            const apiKey = localStorage.getItem(`apikey-${parsedSettings.url}`) || '';
            text2image_apikey = apiKey;
            setSettings(prevSettings => ({
                ...parsedSettings,
                apikey: apiKey,
            }));
        } else {
            // console.log("text2image-settings saved settings not found");
        }

        const promptRefinerSettings = localStorage.getItem('prompt-refiner-settings');
        if (promptRefinerSettings) {
            const parsedSettings = JSON.parse(promptRefinerSettings);
            setPromptRefinerSettings(parsedSettings);
            // console.log("promptRefinerSettings:", parsedSettings);
        } else {
            // console.log("prompt-refiner-settings not found");
            if (text2image_url.includes('siliconflow')) {
                console.log("text2image_url is siliconflow")
                const siliconflow_defaultRefiner = {
                    "provider": "siliconflow",
                    "base_url": "https://api.siliconflow.cn/v1",
                    "model": "Qwen/Qwen2-72B-Instruct",
                    "apiKey": text2image_apikey,
                    'base_url': 'https://api.siliconflow.cn/v1'
                };
                setPromptRefinerSettings(siliconflow_defaultRefiner);
                // console.log("set defaultRefiner:", siliconflow_defaultRefiner);
            }
        }

        const loadImages = async () => {
            const allImages = await db.images.toArray();
            setImages(allImages);
        };

        loadImages();

        if (promptContainerRef.current) {
            promptContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }

        return () => {
            blobUrlsRef.current.forEach(url => URL.revokeObjectURL(url));
        };
    }, []);

    useEffect(() => {
        const loadImages = async () => {
            const allImages = await db.images.toArray();
            const updatedImages = allImages.map(image => {
                if (image.image) {
                    // 为所有带有 image 数据的图片创建新的 Blob URL
                    const blob = new Blob([image.image], { type: 'image/jpeg' });
                    const newUrl = URL.createObjectURL(blob);
                    image.image_url = newUrl;
                    blobUrlsRef.current.set(image.id, newUrl);
                }
                return image;
            });
            setImages(updatedImages);
            if (updatedImages.length > 0) {
                setActiveTab('generated');
            }
        };

        loadImages();

        return () => {
            blobUrlsRef.current.forEach(url => URL.revokeObjectURL(url));
        };
    }, []);

    useEffect(() => {
        const newBlobUrls = new Map();
        images.forEach(image => {
            if (image.image_url) {
                // 对于已有 URL 的图片，直接使用
                newBlobUrls.set(image.id, image.image_url);
            } else if (image.image) {
                // 对于有 image 数据但没有 URL 的图片，创建 Blob URL
                if (blobUrlsRef.current.has(image.id)) {
                    newBlobUrls.set(image.id, blobUrlsRef.current.get(image.id));
                } else {
                    const blobUrl = URL.createObjectURL(new Blob([image.image], { type: 'image/jpeg' }));
                    newBlobUrls.set(image.id, blobUrl);
                }
            }
        });

        // 清理不再使用的 Blob URLs
        blobUrlsRef.current.forEach((url, id) => {
            if (!newBlobUrls.has(id)) {
                URL.revokeObjectURL(url);
            }
        });

        blobUrlsRef.current = newBlobUrls;
    }, [images]);

    const handleSaveSettings = (newSettings) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked Text2Image Save Settings'
          });
        setSettings(newSettings);
        localStorage.setItem('text2image-settings', JSON.stringify(newSettings));
        localStorage.setItem(`apikey-${newSettings.url}`, newSettings.apikey);
    };

    const handleDeleteImage = async (id) => {
        await db.images.delete(id);
        const updatedImages = await db.images.toArray();
        setImages(updatedImages);
    };

    const handleGenerate = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked Text2Image'
          });
          if (!settings.apikey) {
            setSettingsTab('text2image');
            setShowSettings(true);
            toast.error("Please enter your API key in the settings and click 'Get API Key' to obtain one.");
            return;
        }
        const interval = setInterval(() => {
            setImages(prevImages => {
                const updatedImages = [...prevImages];
                if (updatedImages[currentIndex]) {
                    const progress = Math.min((Date.now() - startTime) / 15000 * 100, 99);
                    updatedImages[currentIndex].progress = progress;
                }
                return updatedImages;
            });
        }, 100);
        setActiveTab('generated');
        setPrompt('');
        const currentSeed = settings.seed ? parseInt(settings.seed) : Math.floor(Math.random() * MAX_SEED);
        let body;
        const newImage = {
            id: Date.now(),
            seed: currentSeed,
            error: '',
            prompt,
            negative_prompt: settings.negative_prompt
        };

        setImages(prevImages => [...prevImages, newImage]);
        const currentIndex = images.length;

        const startTime = Date.now();
        if (settings.url.includes('fal-ai')) {
            try {
                fal.config({
                    credentials: settings.apikey
                  });            
              const result = await fal.subscribe(settings.url, {
                input:{
                prompt,
                image_size: settings.image_size,
                num_inference_steps: settings.num_inference_steps,
                seed: currentSeed,
                negative_prompt: settings.negative_prompt,
                sync_mode: true,
                num_images: 1
                },
                logs: true,
              });
        
              clearInterval(interval);
        
              if (result.images && result.images.length > 0) {
                const imageUrl = result.images[0].url;
                const new_retImage = {
                  prompt,
                  negative_prompt: settings.negative_prompt,
                  seed: currentSeed,
                  steps: settings.num_inference_steps,
                  guidance_scale: settings.guidance_scale,
                  image: null,
                  image_url: imageUrl
                }
                await db.images.add(new_retImage);
                setImages(prevImages => {
                  const updatedImages = [...prevImages];
                  updatedImages[currentIndex] = new_retImage;
                  return updatedImages;
                });
                setActiveTab('generated');
              } else {
                throw new Error('No images returned from API');
              }
            } catch (error) {
              clearInterval(interval);
              setImages(prevImages => {
                const updatedImages = [...prevImages];
                updatedImages[currentIndex].error = 'Error generating image';
                return updatedImages;
              });
              console.error('Error generating image:', error);
            }
          } else {
        let width = 1024;
        let height = 1024;
        if (settings.url.includes('prodia')) {

            if (settings.image_size) {
                width = parseInt(settings.image_size.split('x')[0]);
                height = parseInt(settings.image_size.split('x')[1]);
            }
            body = {
                type: "v2.job.sd3.txt2img",
                config: {
                    prompt,
                    negative_prompt: settings.negative_prompt,
                    width: width,
                    height: height,
                    guidance_scale: settings.guidance_scale,
                    steps: settings.num_inference_steps,
                    seed: currentSeed
                }
            };
        } else if (settings.url.includes('siliconflow')) {
            body = {
                prompt,
                negative_prompt: settings.negative_prompt,
                width: width,
                height: height,
                guidance_scale: settings.guidance_scale,
                steps: settings.num_inference_steps,
                seed: currentSeed
            };
        }

        const headers = {
            'accept': 'application/json',
            'content-type': 'application/json'
        };

        if (settings.url.includes('prodia')) {
            headers['X-Prodia-Key'] = settings.apikey;
        } else {
            headers['authorization'] = `Bearer ${settings.apikey}`;
        }

        try {
            let response;
            if (settings.url.includes('api.stability.ai')) {
                const payload = {
                    prompt,
                    negative_prompt: settings.negative_prompt,
                    aspect_ratio: settings.aspect_ratio || '1:1',
                    guidance_scale: settings.guidance_scale,
                    steps: settings.num_inference_steps,
                    seed: currentSeed,
                    output_format: 'jpeg'
                };

                const formData = new FormData();
                Object.keys(payload).forEach(key => formData.append(key, payload[key]));

                response = await axios.post(settings.url, formData, {
                    headers: {
                        'Authorization': `Bearer ${settings.apikey}`,
                        'Accept': 'image/*'
                    },
                    responseType: 'arraybuffer'
                });
                // console.log(settings.url, body);
            } else {
                response = await axios.post(settings.url, body, {
                    headers,
                    responseType: settings.url.includes('prodia') ? 'arraybuffer' : 'json'
                });
                // console.log(settings.url, body);
            }

            clearInterval(interval);

            if (response.status === 200) {
                let imageData, imageUrl;
                if (settings.url.includes('prodia') || settings.url.includes('api.stability.ai')) {
                    imageData = new Uint8Array(response.data);
                    const blob = new Blob([imageData], { type: 'image/jpeg' });
                    imageUrl = URL.createObjectURL(blob);
                    // console.log("return from formdata")
                } else if (settings.url.includes('siliconflow')) {
                    const result = response.data;
                    if (result.images && result.images.length > 0) {
                        imageUrl = result.images[0].url;
                        imageData = null;
                    } else {
                        throw new Error('No images returned from API');
                    }
                    // console.log("return from siliconflow")
                }
                const new_retImage = {
                    prompt,
                    negative_prompt: settings.negative_prompt,
                    seed: currentSeed,
                    steps: settings.num_inference_steps,
                    guidance_scale: settings.guidance_scale,
                    image: imageData,
                    image_url: imageUrl
                }
                await db.images.add(new_retImage);
                // console.log("after insert image into local db")
                setImages(prevImages => {
                    const updatedImages = [...prevImages];
                    updatedImages[currentIndex] = new_retImage;
                    return updatedImages;
                });
                // console.log("update images list")
                // const updatedImages = await db.images.toArray();
                // setImages(updatedImages);
                setActiveTab('generated');
            } else if (response.status === 402) {
                clearInterval(interval);
                setImages(prevImages => {
                    const updatedImages = [...prevImages];
                    updatedImages[currentIndex].error = 'Out of credits.';
                    return updatedImages;
                });
            } else {
                throw new Error(`${response.status}: ${response.data.toString()}`);
            }
        } catch (error) {
            clearInterval(interval);
            setImages(prevImages => {
                const updatedImages = [...prevImages];
                updatedImages[currentIndex].error = 'Error generating image';
                return updatedImages;
            });
            console.error('Error generating image:', error);
        }
    }
    };

    const handleRegenerate = (examplePrompt, exampleNegativePrompt) => {
        setPrompt(examplePrompt);
        setSettings(prevSettings => ({
            ...prevSettings,
            negative_prompt: exampleNegativePrompt
        }));
    };

    const handleTranslate = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked Text2Image Translate'
        });
        if (!promptRefinerSettings.apiKey || !promptRefinerSettings.model) {
            setSettingsTab('promptRefiner');
            setShowSettings(true);
            toast.error("Please set up the Prompt Refiner API key and model in the settings.");
            return;
        }

        setIsTranslating(true);

        const translatePrompt = `Please create an image generation english prompt to fit this brief:\n"${prompt}"\nPlease add more detail and nuance to the prompt and please ONLY reply with the prompt and nothing else. DO NOT include "Prompt: " or any other precursor, just the prompt itself, otherwise your comments also get used in the image generation.`;

        try {
            const openai = new OpenAI({
                baseURL: promptRefinerSettings.base_url,
                apiKey: promptRefinerSettings.apiKey,
                defaultHeaders: {
                    "HTTP-Referer": "llmstock.com",
                    "X-Title": "llmstock"
                },
                dangerouslyAllowBrowser: true,
                fetch: (url, init) => {
                    if (init.headers) {
                        const headers = new Headers(init.headers);
                        headers.delete('x-stainless-retry-count');
                        headers.delete('x-stainless-runtime');
                        headers.delete('x-stainless-runtime-version');
                        init.headers = headers;
                    }
                    return fetch(url, init);
                }
            });

            const controller = new AbortController();
            const signal = controller.signal;

            const stream = await openai.chat.completions.create({
                model: promptRefinerSettings.model,
                messages: [{ role: 'user', content: translatePrompt }],
                max_tokens: 4096,
                temperature: 0.7,
                stream: true
            }, { signal });

            let translatedPrompt = '';
            for await (const chunk of stream) {
                const content = chunk.choices[0]?.delta?.content || "";
                translatedPrompt += content;
                setPrompt(prevPrompt => prevPrompt + content);
            }

            setPrompt(translatedPrompt.trim());
            toast.success('Prompt translated successfully!');
        } catch (error) {
            if (error.name === 'AbortError') {
                // console.log('Translation aborted');
            } else {
                // console.error('Error translating prompt:', error);
                toast.error('Error translating prompt.');
            }
        } finally {
            setIsTranslating(false);
        }
    };

    const handleCategoryChange = (categoryId) => {
        setSelectedCategory(categoryId);
        setPage(1);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePrevPage = () => {
        if (page > 1) {
            handlePageChange(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            handlePageChange(page + 1);
        }
    };

    return (
        <>
        <SEO
        title="Text to image, prompt refiners and examples, support flux,sd3 and sota aigc models"
        description="text to image, image generation examples,prompt engineering with flux,sd3 and sota aigc models"
      />
        <div className="relative flex flex-col h-full">
            <Tabs.Root className="TabsRoot" value={activeTab} onValueChange={setActiveTab}>
                <Tabs.List className="TabsList" aria-label="Manage your images">
                    <Tabs.Trigger className="TabsTrigger" value="generated">
                        Generated
                    </Tabs.Trigger>
                    <Tabs.Trigger className="TabsTrigger" value="example">
                        Example
                    </Tabs.Trigger>
                </Tabs.List>

                <Tabs.Content className="TabsContent" value="generated">
                    <div className="flex-1 overflow-auto p-2">
                        {/* <div className="grid-container"> */}
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        
                            {images.map((image) => {
                                let imageUrl = image.image_url;
                                if (!imageUrl && image.image) {
                                    imageUrl = blobUrlsRef.current.get(image.id);
                                }
                                return (
                                    <ImageCard
                                        key={image.id}
                                        image={imageUrl}
                                        seed={image.seed}
                                        prompt={image.prompt}
                                        negativePrompt={image.negative_prompt}
                                        otherParams={{ ...settings, prompt: undefined, apikey: undefined, url: undefined }}
                                        onDelete={() => handleDeleteImage(image.id)}
                                        error={image.error}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </Tabs.Content>

                <Tabs.Content className="TabsContent" value="example">
  <RadioGroup.Root
    className="flex flex-wrap gap-2 mb-4"
    value={selectedCategory}
    onValueChange={handleCategoryChange}
  >
    <RadioGroup.Item value="all" className="px-4 py-2 rounded-full bg-gray-200 hover:bg-gray-300">
      All
    </RadioGroup.Item>
    {categories.map((category) => (
      <RadioGroup.Item
        key={category.id}
        value={category.id.toString()}
        className="px-4 py-2 rounded-full bg-gray-200 hover:bg-gray-300"
      >
        {category.name}
      </RadioGroup.Item>
    ))}
  </RadioGroup.Root>
  <div className="flex-1 overflow-auto p-2">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {exampleData.map((example, index) => (
        <React.Fragment key={index}>
          <ExampleImageCard
            image={example.url}
            prompt={example.prompt}
            negativePrompt={example.negative_prompt}
            onRegenerate={handleRegenerate}
          />
          {(index) % 5 === 0 && <FeedAdComponent index={Math.floor(index / 5)}/>} {/* 每5个插入一个广告 */}
        </React.Fragment>
      ))}
    </div>
  </div>
  {totalPages > 1 && (
    <div className="pagination-container mt-4 flex justify-center">
      <button
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
        className="px-4 py-2 bg-blue-500 text-white rounded mr-2"
      >
        Previous
      </button>
      <span className="px-4 py-2">Page {page} of {totalPages}</span>
      <button
        onClick={() => handlePageChange(page + 1)}
        disabled={page === totalPages}
        className="px-4 py-2 bg-blue-500 text-white rounded ml-2"
      >
        Next
      </button>
    </div>
  )}
</Tabs.Content>

            </Tabs.Root>

            {/* 添加悬浮的导航按钮 */}
            <button
                    className="absolute left-4 top-float bg-white p-2 rounded-full shadow-lg"
                    onClick={handlePrevPage}
                    disabled={page === 1}
                >
                    {LsIcons.Dashboards_left_arrow_svg_icon}
                </button>
                <button
                    className="absolute right-4 top-float bg-white p-2 rounded-full shadow-lg"
                    onClick={handleNextPage}
                    disabled={page === totalPages}
                >
                    {LsIcons.Dashboards_right_arrow_svg_icon}
                </button>

            <div ref={promptContainerRef} className="prompt-container absolute bottom-0 left-0 w-full bg-white p-4 shadow-lg">
                <div className="flex items-center justify-between">
                    <Tooltip.Provider>
                        <Tooltip.Root>
                            <Tooltip.Trigger asChild>
                                <button
                                    className="settings-button bg-blue-500 text-white p-2 rounded m-1"
                                    onClick={() => setShowSettings(true)}
                                >
                                    {LsIcons.Text2Image_settings_icon}
                                </button>
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                    More settings
                                    <Tooltip.Arrow className="TooltipArrow" />
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>

                        <Tooltip.Root>
                            <Tooltip.Trigger asChild>
                                <input
                                    type="text"
                                    placeholder="Enter prompt..."
                                    value={prompt}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    className="prompt-input flex-grow p-2 border border-gray-300 rounded"
                                />
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                    Enter your prompt here
                                    <Tooltip.Arrow className="TooltipArrow" />
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>

                        <Tooltip.Root>
                            <Tooltip.Trigger asChild>
                                <button
                                    className="translate-button bg-yellow-500 text-white p-2 rounded m-1"
                                    onClick={handleTranslate}
                                    disabled={isTranslating}
                                >
                                    {isTranslating ? (
                                        <div className="loader"></div>
                                    ) : (
                                        LsIcons.Text2Image_rewrite_prompt_icon
                                    )}
                                </button>
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                    Translate prompt
                                    <Tooltip.Arrow className="TooltipArrow" />
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>

                        <Tooltip.Root>
                            <Tooltip.Trigger asChild>
                                <button
                                    className="generate-button bg-green-500 text-white p-1 rounded m-1"
                                    onClick={handleGenerate}
                                >
                                    Generate
                                </button>
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                    Generate image
                                    <Tooltip.Arrow className="TooltipArrow" />
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>
                    </Tooltip.Provider>
                </div>
            </div>

            <SettingsDialog
                open={showSettings}
                onOpenChange={setShowSettings}
                settings={settings}
                setSettings={handleSaveSettings}
                promptRefinerSettings={promptRefinerSettings}
                setPromptRefinerSettings={setPromptRefinerSettings}
                initialTab={settingsTab}
            />

            <ToastContainer />
        </div>
        </>
    );
};

export default Text2Image;
