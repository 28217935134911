import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMenu } from './MenuContext';
import AuthButtons from './AuthButtons';
import { LsIcons } from './ui/LsIcons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Separator from '@radix-ui/react-separator';
import "./ui/css/radixseporator.css"

import {
  DotsVerticalIcon,
  Pencil1Icon,
  MinusIcon,
  OpenInNewWindowIcon,
} from '@radix-ui/react-icons';

const SidebarMenu = ({ isSideMenuOpen, setIsSideMenuOpen, isAuthenticated, setIsAuthenticated, userInfo, handleLogout }) => {
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [editingSubMenu, setEditingSubMenu] = useState(null);
  const { menuItems, addSubMenu, removeSubMenu, renameSubMenu, forkMenu } = useMenu();
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isTogglingMenu, setIsTogglingMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const playgroundMenuIndex = 2;
    if (menuItems[playgroundMenuIndex].children && menuItems[playgroundMenuIndex].children.length > 0) {
      setExpandedMenu(playgroundMenuIndex);
    }
  }, [menuItems]);

  const handleToggleMenu = (index, event) => {
    setIsTogglingMenu(true);
    setExpandedMenu(expandedMenu === index ? null : index);
    setTimeout(() => {
      setIsTogglingMenu(false);
    }, 300);
  };  

  const handleMenuItemClick = (index, event) => {
    // 保持原有逻辑
  };

  useEffect(() => {
    const path = location.pathname;
    let foundMenuItem = null;
    let foundSubMenu = null;

    menuItems.forEach((item, index) => {
      if (item.path === path) {
        foundMenuItem = index;
      }
      if (item.children) {
        item.children.forEach((subItem, subIndex) => {
          if (subItem.path === path) {
            foundMenuItem = index;
            foundSubMenu = subIndex;
          }
        });
      }
    });

    setActiveMenuItem(foundMenuItem);
    setActiveSubMenu(foundSubMenu !== null ? { parentIndex: foundMenuItem, subIndex: foundSubMenu } : null);
  }, [location, menuItems]);

  const handleEditSubMenu = (parentIndex, subIndex) => {
    setEditingSubMenu({ parentIndex, subIndex });
  };

  const handleSaveSubMenu = (parentIndex, subIndex, newLabel) => {
    renameSubMenu(parentIndex, subIndex, newLabel);
    setEditingSubMenu(null);
  };

  const handleForkSubMenu = (parentIndex, subIndex) => {
    forkMenu(parentIndex, subIndex);
  };

  const truncateLabel = (label) => {
    return label.length > 18 ? label.substring(0, 15) + '...' : label;
  };

  const handleInputFocus = (e) => {
    e.stopPropagation(); 
    e.target.select();
  };

  const handleInputKeyDown = (e, parentIndex, subIndex) => {
    if (e.key === 'Enter') {
      handleSaveSubMenu(parentIndex, subIndex, e.target.value);
    }
  };

  const handleClickLink = (e) => {
    if (window.innerWidth < 768) {
      if (e.target.tagName.toLowerCase() !== 'input') {
        setIsSideMenuOpen(false);
      } else {
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  const handleCloseMenu = () => {
    setIsSideMenuOpen(false);
  };

  const handleSubMenuClick = (parentIndex, subIndex) => {
    // 可以在这里添加对子菜单点击的处理逻辑
  };

  const isMenuItemActive = (index) => {
    return activeMenuItem === index ? 'bg-gray-700' : '';
  };

  const isSubMenuActive = (parentIndex, subIndex) => {
    return activeSubMenu && activeSubMenu.parentIndex === parentIndex && activeSubMenu.subIndex === subIndex
      ? 'bg-gray-700'
      : '';
  };

  return (
    <div className={`md:flex flex-col w-64 bg-gray-900 ${isSideMenuOpen ? 'block' : 'hidden'} md:block`}>
      {/* 移动端头部 */}
      <div className="flex items-center justify-between h-2 bg-gray-900 px-4 md:hidden">
        <button onClick={() => setIsSideMenuOpen(!isSideMenuOpen)} className="text-white">
          {LsIcons.SideMenu_open_svg_icon}
        </button>
        {/* <span className="text-white font-bold uppercase">LLM stock beta</span> */}
        {/* <img src="/icons/llmstocklogo.svg" alt="LLM Stock Logo" className="h-6 w-auto" /> */}
      </div>

      {/* 桌面端头部 */}
      {/* <div className="hidden md:flex items-center h-16 border-gray-300 bg-gray-900 px-6">
        <span className="text-white font-bold uppercase">LLM stock beta</span>
        <img src="/icons/llmstocklogo.svg" alt="LLM Stock Logo" className="h-6 w-auto" />
      </div> */}

<div className="hidden md:flex items-center h-16 border-gray-300 bg-gray-900 px-6 align-items: flex-start justify-content: flex-end">
        <img src="/icons/llmstocklogo.svg" alt="LLM Stock Logo" className="h-6 w-auto mr-2" />
        <span className="text-white font-bold uppercase">LLM stock beta</span>
      </div> 

      <nav className="flex-1 px-2 py-1 bg-gray-900">
        {menuItems.map((item, index) => (
          <div key={index}>
            <div className={`flex items-center justify-between px-4 py-2 text-gray-100 hover:bg-gray-700 md:py-1 ${isMenuItemActive(index)}`}
            onClick={(event) => handleMenuItemClick(index, event)}>
              {item.path === "" ? (
                <div id="ToggleMenuBtn" className="flex items-center flex-grow" onClick={(event) => handleToggleMenu(index, event)}>
                  {item.icon}
                  <span className="ml-1">{item.label}</span>
                </div>
              ) : (
                <Link to={item.path} onClick={handleClickLink} className="flex items-center flex-grow">
                  {item.icon}
                  <span className="ml-1">{item.label}</span>
                </Link>
              )}
              <div className="flex items-center">
                {item.children && item.children.length > 0 && (
                  <button onClick={() => handleToggleMenu(index)} className="ml-1 text-gray-300 hover:text-white">
                    {expandedMenu === index ? (LsIcons.SubMenu_expand_svg_icon) : (LsIcons.SubMenu_unexpand_svg_icon)}
                  </button>
                )}
              </div>
            </div>
            {expandedMenu === index && item.children && item.children.length > 0 && (
              <div className="ml-2">
                {item.children.map((subItem, subIndex) => (
                  <div key={subIndex} className={`flex items-center justify-between px-4 py-2 text-gray-100 hover:bg-gray-700 ${isSubMenuActive(index, subIndex)}`}
                  onClick={() => handleSubMenuClick(index, subIndex)}>
                    {editingSubMenu && editingSubMenu.parentIndex === index && editingSubMenu.subIndex === subIndex ? (
                      <input
                        type="text"
                        defaultValue={subItem.label}
                        onBlur={(e) => {
                          e.stopPropagation();
                          handleSaveSubMenu(index, subIndex, e.target.value);
                        }}
                        autoFocus
                        onFocus={handleInputFocus}
                        onKeyDown={(e) => {
                          e.stopPropagation();
                          handleInputKeyDown(e, index, subIndex);
                        }}
                        className="bg-gray-700 text-white px-2 py-1 rounded w-full"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onChange={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    ) : (
                      <Link to={subItem.path} onClick={handleClickLink} className="flex items-center flex-grow">
                        <span className="ml-1">{truncateLabel(subItem.label)}</span>
                      </Link>
                    )}
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className="ml-2 text-gray-300 hover:text-white" aria-label="Options">
                          <DotsVerticalIcon />
                        </button>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Portal>
                        <DropdownMenu.Content className="bg-gray-900 text-white rounded shadow-lg w-48 p-2" sideOffset={5}>
                          <DropdownMenu.Item className="flex items-center px-2 py-2 hover:bg-gray-700 cursor-pointer" onClick={() => removeSubMenu(index, subIndex)}>
                            <MinusIcon className="mr-2" /> Delete chat
                          </DropdownMenu.Item>
                          <DropdownMenu.Item className="flex items-center px-2 py-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleEditSubMenu(index, subIndex)}>
                            <Pencil1Icon className="mr-2" /> Rename chat
                          </DropdownMenu.Item>
                          <DropdownMenu.Item className="flex items-center px-2 py-2 hover:bg-gray-700 cursor-pointer" onClick={() => handleForkSubMenu(index, subIndex)}>
                            <OpenInNewWindowIcon className="mr-2" /> Fork chat
                          </DropdownMenu.Item>
                          <DropdownMenu.Arrow className="fill-gray-800" />
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
      <Separator.Root className="SeparatorRoot" orientation="horizontal" size="3" style={{ margin: '0 15px' }} />
      <div className="px-4 py-2">
        {isAuthenticated && userInfo ? (
          <div className="flex flex-col items-start">
            <div className="flex items-center">
              <img src={userInfo.icon} alt="User Icon" className="w-8 h-8 rounded-full" />
              <span className="ml-2 text-white">{userInfo.username}</span>
            </div>
            <button onClick={handleLogout} className="mt-2 text-gray-300 hover:text-white">Logout</button>
          </div>
        ) : (
          <AuthButtons setIsSideMenuOpen={setIsSideMenuOpen} setIsAuthenticated={setIsAuthenticated} />
        )}
      </div>
    </div>
  );
};

export default SidebarMenu;
