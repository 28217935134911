import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const HomeBlogPreview = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;
    const defaultImageUrl = '/images/default_blog.jpg';
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                setLoading(true);
                const query = `
          query {
            posts(limit: 6, sort: "date:DESC") {
              id
              title
              slug
              date
              cover {
                url
              }
            }
          }
        `;

                const response = await axios.post(`${apiUrl}/graphql`, { query });
                setPosts(response.data.data.posts);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };

        fetchPosts();
    }, [apiUrl]);

    if (loading) {
        return <LoadingSpinner>Loading...</LoadingSpinner>;
    }

    const handlePostClick = (postId) => {
        navigate(`/post/${postId}`);
    };

    const handleViewMoreClick = (e) => {
        e.preventDefault();
        navigate('/blog');
    };

    return (
        <BlogPreviewContainer>
            <SectionHeader>
                <SectionTitle>Latest Insights</SectionTitle>
                <SectionSubtitle>Stay informed with our latest market, AI and tech news.</SectionSubtitle>
            </SectionHeader>
            <PostGrid>
                {posts.map((post, index) => (
                    <motion.div
                        key={post.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                        <BlogPostPreview onClick={() => handlePostClick(post.id)}>
                            <PostContent>
                                <TextContent>
                                    <PostTitle>{truncate(post.title, 70)}</PostTitle>
                                    <PostDate>{new Date(post.date).toLocaleDateString()}</PostDate>
                                </TextContent>
                                <PostImage>
                                    <img
                                        src={post.cover && post.cover.length > 0 ? `${apiUrl}${post.cover[0].url}` : defaultImageUrl}
                                        alt={post.title}
                                    />
                                </PostImage>
                            </PostContent>
                            <GlassEffect />
                        </BlogPostPreview>
                    </motion.div>
                ))}
            </PostGrid>
            <MoreLinkContainer>
                <MoreLink onClick={handleViewMoreClick}>Explore All Articles</MoreLink>
            </MoreLinkContainer>
        </BlogPreviewContainer>
    );
};

const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
};

const BlogPreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px 0;
    background: linear-gradient(135deg, #f6f9fc 0%, #e9f2f9 100%);
`;

const SectionHeader = styled.div`
    text-align: center;
    margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
`;

const SectionSubtitle = styled.p`
    font-size: 1rem;
    color: #666;
`;

const PostGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const BlogPostPreview = styled.div`
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;
    position: relative;
    overflow: hidden;

    &:hover {
        transform: translateY(-3px);
    }
`;

const GlassEffect = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;

    ${BlogPostPreview}:hover & {
        opacity: 1;
    }
`;

const PostContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
`;

const TextContent = styled.div`
    flex: 1;
    padding-right: 10px;
`;

const PostTitle = styled.h3`
    margin: 0 0 5px 0;
    font-size: 1rem;
    color: #333;
    font-weight: 500;
`;

const PostDate = styled.span`
    font-size: 0.8rem;
    color: #999;
`;

const PostImage = styled.div`
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 4px;
    flex-shrink: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
    }

    ${BlogPostPreview}:hover & img {
        transform: scale(1.1);
    }
`;

const MoreLinkContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px 0;
`;

const MoreLink = styled.a`
    text-decoration: none;
    color: #4CAF50;
    font-weight: bold;
    cursor: pointer;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;

    &:hover {
        color: #45a049;
    }
`;

const LoadingSpinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 1.2rem;
    color: #666;
`;

export default HomeBlogPreview;
