import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import { LsIcons } from "@/components/ui/LsIcons";
import * as Tooltip from '@radix-ui/react-tooltip';
import "../css/tooltip.css"
import "../css/market.css"
import { motion } from 'framer-motion';
import SEO from './seo/SEO';

const Dashboards = () => {
  const navigate = useNavigate();
  const [dashboards, setDashboards] = useState([]);
  const [newDashboardName, setNewDashboardName] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalDashboards, setTotalDashboards] = useState(0);
  const pageSize = 12; // Adjust page size as needed
  const location = useLocation();

  const AnimatedButton = () => (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <motion.a
            href="https://llmstock.com/specialoffer"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white text-white font-bold py-2 px-4 rounded-full shadow-lg flex items-center" // 修改这里
            whileHover={{ scale: 1.1, rotate: 360 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
          >
            <motion.img
              src="/icons/dollar-symbol-money.svg"
              alt="Trade"
              className="w-6 h-6"
              initial={{ opacity: 0.6 }}
              animate={{ opacity: 1 }}
              transition={{ yoyo: Infinity, duration: 2 }}
            />
          </motion.a>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="ms-TooltipContent"
            sideOffset={5}
          >
            Open trade account now
            <Tooltip.Arrow className="ms-TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Dashboards" });
  }, []);

  useEffect(() => {
    fetchTotalDashboards();
    fetchDashboards();
  }, [currentPage, totalDashboards]);

  const fetchTotalDashboards = async () => {
    const luserId = localStorage.getItem('luserid');
    const siteAccessToken = localStorage.getItem('accessToken');
    const response = await fetch(`/api/dashboard-count/${luserId}`, {
      headers: { 'Authorization': `Bearer ${siteAccessToken}` }
    });
    if (response.ok) {
      const data = await response.json();
      setTotalDashboards(data.count);
    }
  };

  const fetchDashboards = async () => {
    const luserId = localStorage.getItem('luserid');
    const siteAccessToken = localStorage.getItem('accessToken');
    const response = await fetch(`/api/dashboards/${luserId}?page=${currentPage}&size=${pageSize}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${siteAccessToken}`
      }
    });
    if (!response.ok) {
      if (response.status === 401) {
        // If 401 error, redirect to login page
        navigate('/login');
      }
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setDashboards(data);
  };

  const handleAddDashboard = async () => {
    if (newDashboardName.trim() === '') return;

    const luserId = localStorage.getItem('luserid');
    const newDashboard = {
      name: newDashboardName,
      tickets: []
    };
    const siteAccessToken = localStorage.getItem('accessToken');
    const addresp = await fetch('/api/adddashboard', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${siteAccessToken}`,
        'Content-Type': 'application/json'  // Ensure Content-Type is set to application/json
      },
      body: JSON.stringify({
        luserid: luserId,
        dashboardname: newDashboardName,
        tickets: [],
      }),

    });
    if (!addresp.ok) {
      if (addresp.status === 401) {
        navigate('/login');
      }
      throw new Error('Network response was not ok');
    }

    const addDashboardData = await addresp.json();
    let mergedDashboardInfo = {
      ...newDashboard,
      did: addDashboardData.did,
    };
    setDashboards([...dashboards, mergedDashboardInfo]);
    setNewDashboardName('');
    fetchDashboards(); // Refresh dashboards list
  };

  const handleDashboardClick = (dashboardId) => {
    navigate(`/dashboard/${dashboardId}`);
  };  

  const handleDeleteDashboard = async (dashboardId) => {
    const luserId = localStorage.getItem('luserid');
    const siteAccessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch('/api/removedashboard', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${siteAccessToken}`,
          'Content-Type': 'application/json'  // Ensure Content-Type is set to application/json
        },
        body: JSON.stringify({
          luserid: luserId,
          did: dashboardId,
        }),
      });
      if (!response.ok) {
        if (response.status === 401) {
          navigate('/login');
        }
        throw new Error('Network response was not ok');
      }
    } catch (err) {
      console.error('Failed to delete dashboard:', err);
    }
    setDashboards(dashboards.filter(dashboard => dashboard.did !== dashboardId));
  };

  const nextPage = () => {
    if ((currentPage + 1) * pageSize < totalDashboards) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
            <SEO
        title="manage stock dashbords"
        description="Manage your stock dashboards here, compositions, and more. one place monitor for stocks, portfolioss"
      />
    <Tooltip.Provider>
      <div className="dashboards-page p-5">
        <div className="flex flex-col sm:flex-row items-center justify-center gap-2">
          <div className="add-dashboard w-full sm:w-auto flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
            <input
              type="text"
              value={newDashboardName}
              onChange={(e) => setNewDashboardName(e.target.value)}
              placeholder="Enter Dashboard Name"
              className="p-2 rounded border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 w-full sm:w-auto"
            />
            <div className="flex space-x-2 w-full sm:w-auto justify-center sm:justify-start">
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <button onClick={handleAddDashboard} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center">
                    {LsIcons.Dashboards_add_svg_icon}
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content className="TooltipContent" side="top">
                    <Tooltip.Arrow className="TooltipArrow" />
                    Add Dashboard
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <button onClick={previousPage} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center">
                    {LsIcons.Dashboards_left_arrow_svg_icon}
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content className="TooltipContent" side="top">
                    <Tooltip.Arrow className="TooltipArrow" />
                    Previous Page
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <button onClick={nextPage} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center">
                    {LsIcons.Dashboards_right_arrow_svg_icon}
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content className="TooltipContent" side="top">
                    <Tooltip.Arrow className="TooltipArrow" />
                    Next Page
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
              <AnimatedButton />
            </div>
          </div>
        </div>

        <div className="dashboards-grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-4">
          {dashboards && dashboards.map((dashboard) => (
            <div key={dashboard.did} onClick={() => handleDashboardClick(dashboard.did)} className="dashboard-card bg-white rounded-lg shadow-md p-5 flex flex-col justify-between h-72 relative">
              <div className="overflow-y-auto max-h-48">
                <h3 className="font-bold text-xl mb-2">{dashboard.name}</h3>
                <ul>
                  {dashboard.tickets && Array.isArray(dashboard.tickets) && dashboard.tickets.map((ticket, index) => (
                    <li key={index} className="text-gray-700 text-base">{ticket}</li>
                  ))}
                </ul>
              </div>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <button onClick={(e) => { e.stopPropagation(); handleDeleteDashboard(dashboard.did); }} className="mr-2 delete-button mt-4 bg-gray-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded absolute bottom-4 right-4 flex items-center">
                    {LsIcons.Remove_svg_icon}
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content className="TooltipContent" side="top">
                    <Tooltip.Arrow className="TooltipArrow" />
                    Delete Dashboard
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </div>
          ))}
        </div>
      </div>
    </Tooltip.Provider>
    </>
  ); 
};

export default Dashboards;
