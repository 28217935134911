import React, { memo, useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "../css/imagecarddialog.css";
import "../css/radixdialog.css";
import { LsIcons } from './ui/LsIcons';
import 'highlight.js/styles/a11y-dark.css';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';

const ImageCard = ({ image, seed, prompt, negativePrompt, otherParams, onDelete, error }) => {
    const [showInfo, setShowInfo] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [dialogSize, setDialogSize] = useState({width: 'auto', height: 'auto'});

    useEffect(() => {
        if (showImage) {
            const img = new Image();
            img.src = image;
            img.onload = () => {
                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;
                const imgWidth = img.width;
                const imgHeight = img.height;

                let width, height;

                if (imgWidth > screenWidth || imgHeight > screenHeight) {
                    const widthRatio = screenWidth / imgWidth;
                    const heightRatio = screenHeight / imgHeight;
                    const ratio = Math.min(widthRatio, heightRatio);
                    width = imgWidth * ratio;
                    height = imgHeight * ratio;
                } else {
                    width = imgWidth;
                    height = imgHeight;
                }

                setDialogSize({width, height});
            };
        }
    }, [showImage, image]);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = image;
        link.download = 'generated_image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // 合并 otherParams 和 seed
    const allParams = { ...otherParams, seed };

    return (
        <div>
            <div className="example-image-card">
                <div className="card-content">
                    {error ? (
                        <div className="card-body">
                            <p className="text-red-500">Error: {error}</p>
                        </div>
                    ) : (
                        image ? (
                            <img
                                src={image}
                                className="card-img"
                                alt="Generated"
                                onClick={() => setShowImage(true)}
                            />
                        ) : (
                            <div className="card-img-placeholder">
                                <div className="loader"></div>
                            </div>
                        )
                    )}
                    {image && (
                        <div className="card-overlay">
                            <div className="card-buttons">
                                <button className="card-button download-button" onClick={handleDownload}>
                                    {LsIcons.Text2Image_download_icon}
                                </button>
                                <button className="card-button info-button" onClick={() => setShowInfo(true)}>
                                    {LsIcons.Text2Image_info_icon}
                                </button>
                                <button className="card-button delete-button" onClick={onDelete}>
                                    {LsIcons.Text2Image_delete_icon}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Dialog.Root open={showInfo} onOpenChange={setShowInfo}>
                <Dialog.Portal>
                    <Dialog.Overlay className="DialogOverlay"/>
                    <Dialog.Content className="DialogContent">
                        <Dialog.Title className="DialogTitle">Image Info</Dialog.Title>
                        <Dialog.Description className="DialogDescription">
                            Details about the generated image and its parameters.
                        </Dialog.Description>
                        <div className="flex flex-col space-y-4">
                            <div className="flex items-center justify-between">
                                <label className="font-bold">Prompt</label>
                                <button className="Button small"
                                        onClick={() => navigator.clipboard.writeText(prompt || '')}>
                                    {LsIcons.Text2Image_copy_svg}
                                </button>
                            </div>
                            <textarea
                                className="border p-2 rounded w-full"
                                rows={Math.min(Math.ceil((prompt || '').length / 45), 6)}
                                readOnly
                                value={prompt || ''}
                            />
                            <div>
                                <label className="font-bold">Negative Prompt</label>
                                <textarea
                                    className="border p-2 rounded w-full"
                                    rows={Math.min(Math.ceil((negativePrompt || '').length / 45), 6)}
                                    readOnly
                                    value={negativePrompt || ''}
                                />
                            </div>
                            <div>
                                <label className="font-bold">Other Parameters</label>
                                <textarea
                                    className="border p-2 rounded w-full"
                                    rows={5}
                                    readOnly
                                    value={JSON.stringify(allParams, null, 2) || ''}
                                />
                            </div>
                        </div>
                        <Dialog.Close asChild>
                            <button className="Button green mt-4">
                                Close
                            </button>
                        </Dialog.Close>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>
            <Dialog.Root open={showImage} onOpenChange={setShowImage}>
                <Dialog.Portal>
                    <Dialog.Overlay className="DialogOverlayImg"/>
                    <Dialog.Content 
                        className="DialogImageContent"
                        style={{width: dialogSize.width, height: dialogSize.height}}
                    >
                        <Dialog.Title>
                            <VisuallyHidden.Root>Image Preview</VisuallyHidden.Root>
                        </Dialog.Title>
                        <Dialog.Description>
                            <VisuallyHidden.Root>
                                Full-size preview of the generated image. Use pinch or scroll to zoom, drag to pan.
                            </VisuallyHidden.Root>
                        </Dialog.Description>
                        <TransformWrapper>
                            <TransformComponent>
                                <img src={image} alt="Generated" className="w-full h-auto rounded-lg"/>
                            </TransformComponent>
                        </TransformWrapper>
                        <Dialog.Close asChild>
                            <button className="DialogImageCloseButton" aria-label="Close">&#x2715;</button>
                        </Dialog.Close>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>
        </div>
    );
};

export default memo(ImageCard);
