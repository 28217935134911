import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import VideoSettingsDialog from './VideoSettingsDialog';
import VideoCard from './VideoCard';
import ExampleVideoCard from './ExampleVideoCard';
import '../css/Text2Image.css';
import * as Tabs from '@radix-ui/react-tabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LsIcons } from './ui/LsIcons';
import Dexie from 'dexie';
import { useLocation } from 'react-router-dom';
import * as Tooltip from '@radix-ui/react-tooltip';
import "../css/tooltip.css";
import * as fal from "@fal-ai/serverless-client";
import ReactGA from "react-ga4";
import { ChatOpenAI } from "@langchain/openai";
import { HumanMessage } from "@langchain/core/messages";
import SEO from './seo/SEO';

const MAX_SEED = 4294967294;

const Text2Video = () => {
    const location = useLocation();
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "text2video" });
    const [prompt, setPrompt] = useState('');
    const [videos, setVideos] = useState([]);
    const [exampleVideos, setExampleVideos] = useState([]);
    const [showSettings, setShowSettings] = useState(false);
    const [activeTab, setActiveTab] = useState('generated');
    const [isTranslating, setIsTranslating] = useState(false);
    const [selectedInputTab, setSelectedInputTab] = useState('text2video');
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [localVideoFile, setLocalVideoFile] = useState(null); // Store the local file reference
    const [fileName, setFileName] = useState(''); // Store the file name
    const promptContainerRef = useRef(null);
    const videoGridRef = useRef(null); // Ref for the video grid
    const blobUrlsRef = useRef(new Map());
    const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;

    const fal_setting = {
        url: 'fal-ai/cogvideox-5b',
        apikey: '',
        video_size: 'landscape_4_3',
        num_inference_steps: 50,
        negative_prompt: 'Distorted, discontinuous, Ugly, blurry, low resolution, motionless, static, disfigured, disconnected limbs, Ugly faces, incomplete arms',
        seed: '',
        guidance_scale: 7, // 默认值为 7
        use_rife: true,
        export_fps: 16,
        strength: 0.8 // 新增参数
    };

    const [settings, setSettings] = useState(fal_setting);

    const [promptRefinerSettings, setPromptRefinerSettings] = useState({
        provider: 'groq',
        base_url: 'https://api.groq.com/openai/v1',
        apiKey: '',
        model: 'gemma2-9b-it',
        available_models: "",
    });

    // Initialize Dexie database
    const db = new Dexie('text2video-db');
    db.version(1).stores({
        videos: '++id,prompt,negative_prompt,seed,steps,guidance_scale,video_data,video_url'
    });

    useEffect(() => {
        const savedSettings = localStorage.getItem('text2video-settings');
        if (savedSettings) {
            const parsedSettings = JSON.parse(savedSettings);
            const apiKey = localStorage.getItem(`apikey-${parsedSettings.url}`) || '';
            setSettings(prevSettings => ({
                ...parsedSettings,
                apikey: apiKey,
            }));
        }

        const promptRefinerSettings = localStorage.getItem('prompt-refiner-settings');
        if (promptRefinerSettings) {
            const parsedSettings = JSON.parse(promptRefinerSettings);
            setPromptRefinerSettings(parsedSettings);
        }

        const loadVideos = async () => {
            const allVideos = await db.videos.toArray();
            const updatedVideos = allVideos.map(video => {
                if (video.video_data) {
                    const blob = new Blob([video.video_data], { type: 'video/mp4' });
                    const videoUrl = URL.createObjectURL(blob);
                    video.video_url = videoUrl;
                    blobUrlsRef.current.set(video.id, videoUrl);
                }
                return video;
            });
            setVideos(updatedVideos);

            // 从 localStorage 恢复加载中的视频
            const loadingVideos = JSON.parse(localStorage.getItem('loading-videos')) || [];
            const validLoadingVideos = loadingVideos.filter(video => video.loading); // 仅保留加载中的视频
            const nonLoadingVideos = loadingVideos.filter(video => !video.loading); // 仅保留已加载完成的错误的视频
            setVideos(prevVideos => [...prevVideos, ...validLoadingVideos, ...nonLoadingVideos]);
            localStorage.setItem('loading-videos', JSON.stringify(validLoadingVideos)); // 更新 localStorage

            // 检查是否有已生成的视频
            if ((updatedVideos.length + validLoadingVideos.length) === 0) {
                setActiveTab('examples');
            }
        };

        loadVideos();

        if (promptContainerRef.current) {
            promptContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }

        return () => {
            blobUrlsRef.current.forEach(url => URL.revokeObjectURL(url));
        };
    }, [apiUrl]);

    useEffect(() => {
        const loadExampleVideos = async () => {
            try {
                const response = await axios.get(`${apiUrl}/t-2-vexamples?&_sort=id:DESC`);
                setExampleVideos(response.data);
            } catch (error) {
                console.error('Error loading example videos:', error);
            }
        };

        loadExampleVideos();
    }, [apiUrl]);

    useEffect(() => {
        const adjustVideoGridHeight = () => {
            if (videoGridRef.current && promptContainerRef.current) {
                const promptContainerHeight = promptContainerRef.current.offsetHeight;
                videoGridRef.current.style.paddingBottom = `${promptContainerHeight + 16}px`; // Adjust for bottom padding
            }
        };

        adjustVideoGridHeight();
        window.addEventListener('resize', adjustVideoGridHeight);
        return () => window.removeEventListener('resize', adjustVideoGridHeight);
    }, []);

    const handleSaveSettings = (newSettings) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked Text2Video Save Settings'
        });
        setSettings(newSettings);
        localStorage.setItem('text2video-settings', JSON.stringify(newSettings));
        localStorage.setItem(`apikey-${newSettings.url}`, newSettings.apikey);
    };

    const handleDeleteVideo = async (id) => {
        const videoToDelete = videos.find(video => video.id === id);
        if (videoToDelete) {
            // 释放 Blob URL
            if (blobUrlsRef.current.has(id)) {
                URL.revokeObjectURL(blobUrlsRef.current.get(id));
                blobUrlsRef.current.delete(id);
            }

            await db.videos.delete(id);
            const updatedVideos = await db.videos.toArray();
            setVideos(updatedVideos);

            // 更新 localStorage 中的加载中视频
            const updatedLoadingVideos = JSON.parse(localStorage.getItem('loading-videos')).filter(video => video.id !== id);
            localStorage.setItem('loading-videos', JSON.stringify(updatedLoadingVideos));

            // 如果没有视频了，切换到 examples tab
            if (updatedVideos.length === 0) {
                setActiveTab('examples');
            }
        }
    };

    const handleGenerate = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked Text2Video'
        });

        if (!settings.apikey) {
            setShowSettings(true);
            toast.error("Please enter your API key in the settings.");
            return;
        }

        setActiveTab('generated');
        const currentSeed = settings.seed ? parseInt(settings.seed) : Math.floor(Math.random() * MAX_SEED);
        const newVideo = {
            id: Date.now(),
            seed: currentSeed,
            error: '',
            prompt: prompt,
            negative_prompt: settings.negative_prompt,
            loading: true // 设置加载状态
        };

        setVideos(prevVideos => [...prevVideos, newVideo]);
        const currentIndex = videos.length;

        // 保存加载中的视频到 localStorage
        const loadingVideos = JSON.parse(localStorage.getItem('loading-videos')) || [];
        localStorage.setItem('loading-videos', JSON.stringify([...loadingVideos, newVideo]));

        try {
            fal.config({
                credentials: settings.apikey
            });

            const videoSizeMap = {
                'square_hd': { height: 720, width: 720 },
                'portrait_4_3': { height: 720, width: 480 },
                'landscape_4_3': { height: 480, width: 720 }
            };

            const video_size = videoSizeMap[settings.video_size] || videoSizeMap['square_hd'];

            const url = selectedInputTab === 'text2video' ? settings.url : 'fal-ai/cogvideox-5b/video-to-video';
            const input = {
                prompt: prompt,
                video_size: video_size,
                num_inference_steps: parseInt(settings.num_inference_steps),
                seed: currentSeed,
                negative_prompt: settings.negative_prompt,
                guidance_scale: parseFloat(settings.guidance_scale),
                use_rife: settings.use_rife,
                export_fps: parseInt(settings.export_fps),
            };

            if (selectedInputTab === 'video2video') {
                if (!localVideoFile) {
                    toast.error("Please upload a video.");
                    return;
                }
                const uploadedUrl = await fal.storage.upload(localVideoFile);
                input.video_url = uploadedUrl;
                input.strength = settings.strength;
            }

            const result = await fal.subscribe(url, {
                input: input,
                logs: true,
                onQueueUpdate: (update) => {
                    if (update.status === "IN_PROGRESS") {
                        update.logs.map((log) => log.message).forEach(console.log);
                    }
                },
            });

            if (result.video && result.video.url) {
                // 下载视频文件并创建 Blob URL
                const response = await axios.get(result.video.url, { responseType: 'blob' });
                const blob = new Blob([response.data], { type: 'video/mp4' });
                const videoUrl = URL.createObjectURL(blob);

                const newRetVideo = {
                    ...newVideo,
                    steps: settings.num_inference_steps,
                    guidance_scale: settings.guidance_scale,
                    video_data: response.data, // 存储视频数据
                    video_url: videoUrl,
                    loading: false // 取消加载状态
                };

                blobUrlsRef.current.set(newRetVideo.id, videoUrl); // 存储 Blob URL

                await db.videos.add(newRetVideo);
                setVideos(prevVideos => {
                    const updatedVideos = [...prevVideos];
                    updatedVideos[currentIndex] = newRetVideo;
                    return updatedVideos;
                });

                // 更新 localStorage 中的加载中视频
                const updatedLoadingVideos = JSON.parse(localStorage.getItem('loading-videos')).filter(video => video.id !== newVideo.id);
                localStorage.setItem('loading-videos', JSON.stringify(updatedLoadingVideos));
                setActiveTab('generated');
            } else {
                throw new Error('No videos returned from API');
            }
        } catch (error) {
            setVideos(prevVideos => {
                const updatedVideos = [...prevVideos];
                updatedVideos[currentIndex].error = 'Error generating video';
                updatedVideos[currentIndex].loading = false; // 取消加载状态
                return updatedVideos;
            });

            // 更新 localStorage 中的加载中视频
            const updatedLoadingVideos = JSON.parse(localStorage.getItem('loading-videos')).filter(video => video.id !== newVideo.id);
            localStorage.setItem('loading-videos', JSON.stringify(updatedLoadingVideos));

            console.error('Error generating video:', error);
        }
    };

    const handleTranslate = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked Text2Video Translate'
        });
        if (!promptRefinerSettings.apiKey || !promptRefinerSettings.model) {
            setShowSettings(true);
            toast.error("Please set up the Prompt Refiner API key and model in the settings.");
            return;
        }

        setIsTranslating(true);

        const translatePrompt = `Please create an image generation english prompt to fit this brief:\n"${prompt}"\nPlease add more detail and nuance to the prompt and please ONLY reply with the prompt and nothing else. DO NOT include "Prompt: " or any other precursor, just the prompt itself, otherwise your comments also get used in the image generation.`;

        try {
            const chat_url = `${promptRefinerSettings.base_url}`;
            console.log("translate url:", chat_url);

            const messageArray = [
                new HumanMessage(translatePrompt)
            ];

            let chat = new ChatOpenAI({
                configuration: {
                    baseURL: chat_url,
                },
                apiKey: promptRefinerSettings.apiKey,
                temperature: parseFloat(0.7),
                maxTokens: 4096,
                model: promptRefinerSettings.model,
                modelName: promptRefinerSettings.model,
                streaming: true,
            });

            const controller = new AbortController();
            const signal = controller.signal;

            const stream = await chat.stream(messageArray, { signal });

            let translatedPrompt = '';
            for await (const chunk of stream) {
                translatedPrompt += chunk.content;
                setPrompt(prevPrompt => prevPrompt + chunk.content);
            }

            setPrompt(translatedPrompt.trim());
            toast.success('Prompt translated successfully!');
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Translation aborted');
            } else {
                console.error('Error translating prompt:', error);
                toast.error('Error translating prompt.');
            }
        } finally {
            setIsTranslating(false);
        }
    };

    const handleVideoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const videoUrl = URL.createObjectURL(file);
            setUploadedVideo(videoUrl);
            setLocalVideoFile(file); // Store the local file reference
            setFileName(file.name); // Store the file name
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            const videoUrl = URL.createObjectURL(file);
            setUploadedVideo(videoUrl);
            setLocalVideoFile(file); // Store the local file reference
            setFileName(file.name); // Store the file name
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <>
                <SEO
        title="Text to video, video to video with sota aigc models"
        description="text to video, video to video, video generation examples,prompt engineering with sota aigc models"
      />
        
        <div className="relative flex flex-col h-full">
            <Tabs.Root className="TabsRoot" value={activeTab} onValueChange={setActiveTab}>
                <Tabs.List className="TabsList" aria-label="Manage your videos">
                    <Tabs.Trigger className="TabsTrigger" value="generated">
                        Generated
                    </Tabs.Trigger>
                    <Tabs.Trigger className="TabsTrigger" value="examples">
                        Examples
                    </Tabs.Trigger>
                </Tabs.List>

                <Tabs.Content className="TabsContent" value="generated">
                    <div ref={videoGridRef} className="flex-1 overflow-auto p-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {videos.map((video) => (
                                <VideoCard
                                    key={video.id}
                                    video={video}
                                    onDelete={handleDeleteVideo}
                                />
                            ))}
                        </div>
                    </div>
                </Tabs.Content>

                <Tabs.Content className="TabsContent" value="examples">
                    <div ref={videoGridRef} className="flex-1 overflow-auto p-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {exampleVideos.map((video) => (
                                <ExampleVideoCard
                                    key={video.id}
                                    video={video}
                                />
                            ))}
                        </div>
                    </div>
                </Tabs.Content>
            </Tabs.Root>
            
            <div ref={promptContainerRef} className="prompt-container absolute bottom-0 left-0 w-full bg-white p-4 shadow-lg">
                <Tabs.Root className="TabsRoot" value={selectedInputTab} onValueChange={setSelectedInputTab}>
                    <Tabs.List className="TabsList" aria-label="Select input method">
                        <Tabs.Trigger className="TabsTrigger" value="text2video">
                            Text2Video
                        </Tabs.Trigger>
                        <Tabs.Trigger className="TabsTrigger" value="video2video">
                            Video2Video
                        </Tabs.Trigger>
                    </Tabs.List>

                    <Tabs.Content className="TabsContentVideoInput" value="text2video">
                        <div className="flex items-center justify-between">
                            <Tooltip.Provider>
                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <button
                                            className="settings-button bg-blue-500 text-white p-2 rounded m-1"
                                            onClick={() => setShowSettings(true)}
                                        >
                                            {LsIcons.Text2Image_settings_icon}
                                        </button>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            More settings
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>

                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <input
                                            type="text"
                                            placeholder="Enter prompt..."
                                            value={prompt}
                                            onChange={(e) => setPrompt(e.target.value)}
                                            className="prompt-input flex-grow p-2 border border-gray-300 rounded"
                                        />
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            Enter your prompt here
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>

                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <button
                                            className="translate-button bg-yellow-500 text-white p-2 rounded m-1"
                                            onClick={handleTranslate}
                                            disabled={isTranslating}
                                        >
                                            {isTranslating ? (
                                                <div className="loader"></div>
                                            ) : (
                                                LsIcons.Text2Image_rewrite_prompt_icon
                                            )}
                                        </button>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            Translate prompt
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>

                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <button
                                            className="generate-button bg-green-500 text-white p-1 rounded m-1"
                                            onClick={handleGenerate}
                                        >
                                            Generate
                                        </button>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            Generate video
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>
                            </Tooltip.Provider>
                        </div>
                    </Tabs.Content>

                    <Tabs.Content className="TabsContentVideoInput" value="video2video">
                        <div className="flex items-center justify-between">
                            <Tooltip.Provider>
                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <button
                                            className="settings-button bg-blue-500 text-white p-2 rounded m-1"
                                            onClick={() => setShowSettings(true)}
                                        >
                                            {LsIcons.Text2Image_settings_icon}
                                        </button>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            More settings
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>

                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <input
                                            type="text"
                                            placeholder="Enter prompt..."
                                            value={prompt}
                                            onChange={(e) => setPrompt(e.target.value)}
                                            className="prompt-input flex-grow p-2 border border-gray-300 rounded"
                                        />
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            Enter your prompt here
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>

                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <button
                                            className="translate-button bg-yellow-500 text-white p-2 rounded m-1"
                                            onClick={handleTranslate}
                                            disabled={isTranslating}
                                        >
                                            {isTranslating ? (
                                                <div className="loader"></div>
                                            ) : (
                                                LsIcons.Text2Image_rewrite_prompt_icon
                                            )}
                                        </button>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            Translate prompt
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>

                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <button
                                            className="generate-button bg-green-500 text-white p-1 rounded m-1"
                                            onClick={handleGenerate}
                                        >
                                            Generate
                                        </button>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            Generate video
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>
                            </Tooltip.Provider>
                        </div>

                        <div className="flex flex-col items-center justify-between mt-4">
                            <Tooltip.Provider>
                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <input
                                            type="file"
                                            accept="video/*"
                                            className="upload-button bg-gray-200 text-black p-1 rounded m-1"
                                            onChange={handleVideoUpload}
                                        />
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            Upload video
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>

                                <Tooltip.Root>
                                    <Tooltip.Trigger asChild>
                                        <div
                                            className="drag-drop-area border-dashed border-gray-300 border-2 p-4 rounded m-1 flex items-center justify-center"
                                            onDrop={handleDrop}
                                            onDragOver={handleDragOver}
                                        >
                                            Drag & Drop video here
                                        </div>
                                    </Tooltip.Trigger>
                                    <Tooltip.Portal>
                                        <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                            Drag and drop video here
                                            <Tooltip.Arrow className="TooltipArrow" />
                                        </Tooltip.Content>
                                    </Tooltip.Portal>
                                </Tooltip.Root>

                                {fileName && (
                                    <div className="mt-2 text-gray-500">
                                        <strong>Selected file:</strong> {fileName}
                                    </div>
                                )}
                            </Tooltip.Provider>
                        </div>
                    </Tabs.Content>
                </Tabs.Root>
            </div>

            <VideoSettingsDialog
                open={showSettings}
                onOpenChange={setShowSettings}
                settings={settings}
                setSettings={handleSaveSettings}
                promptRefinerSettings={promptRefinerSettings}
                setPromptRefinerSettings={setPromptRefinerSettings}
            />

            <ToastContainer />
        </div>
        </>
    );
};

export default Text2Video;
