import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './tailwind.css';
import './styles.css'
import ReactGA from "react-ga4";
ReactGA.initialize('G-K2TEC130FB');

ReactDOM.hydrate(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
