import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import APIChatDB from './db/APIChatDb';
import APISettings from './APISettings';
import { ChatOpenAI, ChatGroq } from "@langchain/openai";
import { HumanMessage, AIMessage } from "@langchain/core/messages";
import TextareaAutosize from "react-textarea-autosize";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import { LsIcons } from './ui/LsIcons';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import CodeCopyBtn from './ui/codeCopyBtn';
import "./ui/tooltip.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import SettingsSidebar from './SettingsSidebar';
import { useChat } from './hooks/useChat';
import ReactGA from "react-ga4";
import SEO from './seo/SEO'; 

const APIChatComponent = ({ sessionId }) => {
    const location = useLocation();
    const { baseurl: initialBaseurl, model: initialModel, apiKey: initialApiKey, maxTokens: initialMaxTokens, temperature: initialTemperature } = location.state || {};
    const [baseurl, setBaseurl] = useState('');
    const [model, setModel] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [maxTokens, setMaxTokens] = useState(2048);
    const [temperature, setTemperature] = useState(0.7);
    const [messages, setMessages] = useState([]);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [contextSize, setContextSize] = useState(3);
    const [onCloud, setOnCloud] = useState(true); // 新增 onCloud 状态
    const [isOllama, setIsOllama] = useState('0');
    const { handleSendMessage, handleStopChat, handleClearChat, handleRegenerate, handleCopyMessage, handleCopyCode } = useChat({
        sessionId, baseurl, model, apiKey, maxTokens, temperature, messages, setMessages, setUserInput, contextSize, userInput, withCloud: onCloud
    });

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "API Chat Component" });
        const loadMessagesAndSettings = async () => {
            const messages = await APIChatDB.messages.where('sessionId').equals(sessionId).toArray();
            setMessages(messages);

            const settings = await APIChatDB.settings.where('sessionId').equals(sessionId).first();
            if (settings) {
                setBaseurl(settings.baseurl);
                setModel(settings.model);
                setApiKey(settings.apiKey);
                setMaxTokens(settings.maxTokens);
                setTemperature(settings.temperature);
                setOnCloud(settings.onCloud !== undefined ? settings.onCloud : false);
                setIsOllama(settings.isOllama !== undefined ? settings.isOllama : '0');
            }
        };

        loadMessagesAndSettings();
    }, [sessionId]);

    const saveSettings = async () => {
        await APIChatDB.settings.where('sessionId').equals(sessionId).delete();
        await APIChatDB.settings.put({ sessionId, baseurl, model, apiKey, maxTokens, temperature, onCloud });
    };

    return (
        <>
        <SEO
        title="chat with llm"
        url="https://llmstock.com/playground"
        description="llm chat website for sota llm models, integrated with best llm apis both paid and free"
      />
        <div className="flex flex-row-reverse flex-grow h-full overflow-x-auto bg-gray-100">
            {isSettingsOpen && (
                <SettingsSidebar
                    saveSettings={saveSettings}
                    baseurl={baseurl}
                    setBaseurl={setBaseurl}
                    model={model}
                    setModel={setModel}
                    apiKey={apiKey}
                    setApiKey={setApiKey}
                    maxTokens={maxTokens}
                    setMaxTokens={setMaxTokens}
                    temperature={temperature}
                    setTemperature={setTemperature}
                    onCloud={onCloud} // 传递 onCloud 状态
                    setOnCloud={setOnCloud} // 传递 setOnCloud 函数
                    isOllama={isOllama}
                    setIsSettingsOpen={setIsSettingsOpen}
                />
            )}
            <div className="flex relative flex-col flex-grow h-full overflow-x-hidden bg-gray-100 p-4">
                <MessageList
                    messages={messages}
                    handleCopyMessage={handleCopyMessage}
                    handleCopyCode={handleCopyCode}
                />
                <MessageInput
                    userInput={userInput}
                    setUserInput={setUserInput}
                    handleSendMessage={() => handleSendMessage(userInput)}
                    handleStopChat={handleStopChat}
                    handleClearChat={handleClearChat}
                    handleRegenerate={handleRegenerate}
                    contextSize={contextSize}
                    setContextSize={setContextSize}
                    isSettingsOpen={isSettingsOpen}
                    setIsSettingsOpen={setIsSettingsOpen}
                />
            </div>
            <ToastContainer />
        </div>
        </>
    );
};

export default APIChatComponent;
