import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useLocation } from 'react-router-dom';
import APISettings from './APISettings';
import { useMenu } from './MenuContext';
import APITemplates, { getAPITemplates } from './hooks/APITemplates';
import { Dock, DockIcon } from "./ui/dock";
import AnimatedDockIcon from './ui/AnimatedDockIcon';
import { AnimatedDock } from './ui/AnimatedDock';
import APIChatDB from './db/APIChatDb';
import { LsIcons } from './ui/LsIcons';
import PassphraseInput from './ui/PassphraseInput';
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Dialog from '@radix-ui/react-dialog';
// 加密库
import CryptoJS from 'crypto-js';
import ReactGA from "react-ga4";
import * as Tooltip from '@radix-ui/react-tooltip';
import "../css/tooltip.css"
import SEO from './seo/SEO';
import ContentAdComponent from './ContentAdComponent';

const Playground = () => {
    const [baseurl, setBaseurl] = useState('');
    const [model, setModel] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [maxTokens, setMaxTokens] = useState(2048);
    const [temperature, setTemperature] = useState(0.7);
    const [apiKeyUrl, setApiKeyUrl] = useState('');
    const [isOllama, setIsOllama] = useState('0');
    const { addSubMenu } = useMenu();
    const navigate = useNavigate();
    const location = useLocation();
    const [decryptAttempts, setDecryptAttempts] = useState(0);
    const startChatRef = useRef(null);

    const baseurlRef = useRef(baseurl);
    const modelRef = useRef(model);
    const apiKeyRef = useRef(apiKey);
    const maxTokensRef = useRef(maxTokens);
    const temperatureRef = useRef(temperature);
    const isOllamaRef = useRef(isOllama);
    const [showPassphraseInput, setShowPassphraseInput] = useState(false);
    const [onCloud, setOnCloud] = useState(false);

    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        const loadTemplates = async () => {
            const loadedTemplates = await getAPITemplates();
            setTemplates(loadedTemplates);
        };
        loadTemplates();
    }, []);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Chat stock Component" });
        setDecryptAttempts(0);
    }, []);

    useEffect(() => {
        baseurlRef.current = baseurl;
    }, [baseurl]);

    useEffect(() => {
        isOllamaRef.current = isOllama;
    }, [baseurl]);

    useEffect(() => {
        modelRef.current = model;
    }, [model]);

    useEffect(() => {
        apiKeyRef.current = apiKey;
    }, [apiKey]);

    useEffect(() => {
        maxTokensRef.current = maxTokens;
    }, [maxTokens]);

    useEffect(() => {
        temperatureRef.current = temperature;
    }, [temperature]);

    useEffect(() => {
        if (startChatRef.current) {
            startChatRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        isOllamaRef.current = isOllama;
    }, [isOllama]);  // 修正依赖项

    const generateChatName = () => {
        const randomNumber = Math.floor(Math.random() * 10000);
        const chatNumber = (randomNumber).toString().padStart(5, '0');
        return `i ${model} ${chatNumber}`;
    };

    const saveSettings = async (sessionId) => {
        await APIChatDB.settings
            .where('sessionId')
            .equals(sessionId)
            .delete();
        await APIChatDB.settings.put({
            sessionId,
            baseurl,
            model,
            apiKey,
            maxTokens,
            temperature,
            onCloud,
            isOllama
        });
    };

    const handleStartChat = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked PlaygroundStartChat'
        });
        const newChatId = uuidv4();
        let newChatUrl = `/chat-session/${newChatId}`;
        // if (baseurlRef.current.startsWith('http://')) {
        //     newChatUrl += '?ssl=0';
        // }
        const chatName = generateChatName();

        addSubMenu(2, chatName, newChatUrl);

        await saveSettings(newChatId);

        navigate(newChatUrl, {
            state: {
                baseurl: baseurlRef.current,
                model: modelRef.current,
                apiKey: apiKeyRef.current,
                maxTokens: maxTokensRef.current,
                temperature: temperatureRef.current,
            },
        });
    };

    const handleShareConfig = (encryptApiKey = false) => {
        let url = `${window.location.origin}${window.location.pathname}?baseurl=${encodeURIComponent(baseurl)}&model=${encodeURIComponent(model)}&maxTokens=${maxTokens}&temperature=${temperature}&apiKeyUrl=${encodeURIComponent(apiKeyUrl)}`;

        if (encryptApiKey && !baseurl.startsWith('http://')) {
            const passphrasePrompt = () => {
                return new Promise((resolve) => {
                    setShowPassphraseInput(true);
                    const completeHandler = (passphrase) => {
                        setShowPassphraseInput(false);
                        resolve(passphrase);
                    };
                    const passphraseInput = (<PassphraseInput onComplete={completeHandler} />);
                    ReactDOM.render(passphraseInput, document.getElementById('passphrase-modal'));
                });
            };

            passphrasePrompt().then((passphrase) => {
                if (passphrase) {
                    const encryptedApiKey = CryptoJS.AES.encrypt(apiKey, passphrase).toString();
                    const url = `${window.location.origin}${window.location.pathname}?baseurl=${encodeURIComponent(baseurl)}&model=${encodeURIComponent(model)}&maxTokens=${maxTokens}&temperature=${temperature}&apiKeyUrl=${encodeURIComponent(apiKeyUrl)}&apiKey=${encodeURIComponent(encryptedApiKey)}&notencrypted=0`;
                    navigator.clipboard.writeText(url).then(() => {
                        toast.success('Configuration with apiKey URL copied to clipboard!');
                    });
                    setShowPassphraseInput(false);
                }
            });
        } else {
            if (baseurl.startsWith('http://')) {
                url += `&apiKey=${encodeURIComponent(apiKey)}&notencrypted=1`;
            }
            navigator.clipboard.writeText(url).then(() => {
                toast.success('Configuration URL copied to clipboard!');
            });
        }
    };

    const [showOllamaInfo, setShowOllamaInfo] = useState(false);
    const handleApiKeyClick = () => {
        if (isOllama === '1') {
            setShowOllamaInfo(true);
        } else {
            window.open(apiKeyUrl, "_blank");
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const encryptedApiKey = params.get('apiKey');
        const notencrypted = params.get('notencrypted', "0") === '1';

        if (encryptedApiKey) {
            if (notencrypted) {
                setApiKey(encryptedApiKey);
            } else if (decryptAttempts < 3) {
                const passphrasePrompt = () => {
                    return new Promise((resolve) => {
                        setShowPassphraseInput(true);
                        const completeHandler = (passphrase) => {
                            setShowPassphraseInput(false);
                            resolve(passphrase);
                        };
                        const passphraseInput = (<PassphraseInput onComplete={completeHandler} />);
                        ReactDOM.render(passphraseInput, document.getElementById('passphrase-modal'));
                    });
                };

                passphrasePrompt().then((passphrase) => {
                    if (passphrase) {
                        try {
                            const decryptedApiKey = CryptoJS.AES.decrypt(encryptedApiKey, passphrase).toString(CryptoJS.enc.Utf8);
                            setApiKey(decryptedApiKey);
                        } catch (error) {
                            toast.error('Passphrase is incorrect. Please try again.');
                            setDecryptAttempts(prevAttempts => prevAttempts + 1);
                            if (decryptAttempts === 2) {
                                toast.error('Too many incorrect attempts. API key decryption is disabled.');
                            }
                        }
                    }
                });
            } else {
                toast.error('Too many incorrect attempts. API key decryption is disabled.');
            }
        }

        setBaseurl(params.get('baseurl') || '');
        setMaxTokens(params.get('maxTokens') ? parseInt(params.get('maxTokens'), 10) : 2048);
        setTemperature(params.get('temperature') ? parseFloat(params.get('temperature')) : 0.7);
        setModel(params.get('model') || '');
        setApiKeyUrl(params.get('apiKeyUrl') || '');
        setIsOllama(params.get('isOllama') || '0');
        console.log("setIsOllama", params.get('isOllama'), isOllama)
    }, [location.search, decryptAttempts]);

    return (
        <>
            <SEO
                title="Chat with llm"
                description="llm chat website for sota llm models, integrated with best llm apis both paid and free"
            />
            <div className="flex relative items-center flex-col justify-center min-h-screen bg-gradient-to-r from-gray-200 via-sky-100 to-pink-50">
                <div className="backdrop-filter backdrop-blur-lg bg-white/30 dark:bg-gray-900/30 shadow-lg rounded-lg p-4 m-4 w-full md:w-3/4 lg:w-1/2 transform transition-transform duration-500 hover:scale-105">
                    <div className="flex z-60" id="passphrase-modal"></div> {/* 确保这个 div 存在 */}
                    <APISettings
                        asSidebar={false}
                        baseurl={baseurl}
                        setBaseurl={setBaseurl}
                        model={model}
                        setModel={setModel}
                        apiKey={apiKey}
                        setApiKey={setApiKey}
                        maxTokens={maxTokens}
                        setMaxTokens={setMaxTokens}
                        temperature={temperature}
                        setTemperature={setTemperature}
                        isOllama={isOllama}
                    />
<div className="flex flex-wrap items-center gap-2 mt-4">
  <input
    type="text"
    id="apiKeyUrl"
    value={apiKeyUrl}
    onChange={(e) => setApiKeyUrl(e.target.value)}
    className="flex-grow min-w-0 border rounded p-1"
    placeholder="API key link"
  />
  <div className="flex flex-nowrap gap-2">
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            onClick={handleApiKeyClick}
            className="bg-blue-500 text-white rounded w-8 h-8 flex items-center justify-center hover:bg-blue-600"
          >
            {/*{LsIcons.Playground_api_link_svg_icon}*/}
              🔑
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="TooltipContent" sideOffset={5}>
            Go to apiKey link
            <Tooltip.Arrow className="TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>

      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button 
            onClick={() => handleShareConfig(false)} 
            className="bg-gray-200 border rounded text-black hover:bg-gray-300 w-8 h-8 flex items-center justify-center"
          >
            {LsIcons.Playground_share_config_svg_icon}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="TooltipContent" sideOffset={5}>
            Share Config without key
            <Tooltip.Arrow className="TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>

      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button 
            onClick={() => handleShareConfig(true)} 
            className="bg-gray-200 border rounded text-black hover:bg-gray-300 w-8 h-8 flex items-center justify-center"
          >
            {LsIcons.Playground_share_config_withkey_svg_icon}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="TooltipContent" sideOffset={5}>
            Share Config with Key
            <Tooltip.Arrow className="TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  </div>
</div>

                    <div className="text-center mt-6">
                        <Tooltip.Provider>
                            <Tooltip.Root>
                                <Tooltip.Trigger asChild>
                                    <button ref={startChatRef} onClick={handleStartChat} className="inline-flex h-12 animate-shimmer items-center justify-center rounded-md border border-slate-800 bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] px-6 font-medium text-slate-400 transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                                        Start Chat
                                    </button>
                                </Tooltip.Trigger>
                                <Tooltip.Portal>
                                    <Tooltip.Content className="TooltipContent" sideOffset={5}>
                                        Start a new chat session
                                        <Tooltip.Arrow className="TooltipArrow" />
                                    </Tooltip.Content>
                                </Tooltip.Portal>
                            </Tooltip.Root>
                        </Tooltip.Provider>
                    </div>

                </div>
                {/* <Dock className="w-full">
                {APITemplates.map((template, index) => (
                    <DockIcon
                        key={index}
                        onClick={() => navigate(template.url)}
                    >
                        {template.icon}
                    </DockIcon>
                ))}
            </Dock> */}
        <div className="w-full py-4">
            <AnimatedDock
                items={templates}
                className="w-full max-w-3xl mx-auto"
            />
</div>



                <ToastContainer />
                <Dialog.Root open={showOllamaInfo} onOpenChange={setShowOllamaInfo}>
                    <Dialog.Overlay className="DialogOverlay" />
                    <Dialog.Content className="DialogContent">
                        <Dialog.Title className="DialogTitle">Ollama Information</Dialog.Title>
                        <div className="mt-4">
                            <p>ollama passwd should be: ollama</p>
                            <p>OLLAMA_ORIGINS=* should be set in env before launch ollama so we can call ollama api locally</p>
                        </div>
                        <div className="flex justify-end mt-4">
                            <Dialog.Close asChild>
                                <button className="Button green">Close</button>
                            </Dialog.Close>
                        </div>
                    </Dialog.Content>
                </Dialog.Root>
            </div>
        </>
    );
};

export default Playground;
