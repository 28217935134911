import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tabs from '@radix-ui/react-tabs';
import "../css/radixdialog.css";
import APITemplates, { getAPITemplates } from './hooks/APITemplates';
import { LsIcons } from './ui/LsIcons';

const MAX_SEED = 4294967294;

const VideoSettingsDialog = ({ open, onOpenChange, settings, setSettings, promptRefinerSettings, setPromptRefinerSettings, initialTab = 'text2video' }) => {
    const [models, setModels] = useState([]);
    const [isLoadingModels, setIsLoadingModels] = useState(false);
    const [fetchModelsError, setFetchModelsError] = useState(null);
    const [localSettings, setLocalSettings] = useState(settings);
    const [localPromptRefinerSettings, setLocalPromptRefinerSettings] = useState(promptRefinerSettings);
    const [currentTab, setCurrentTab] = useState(initialTab);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        const loadTemplates = async () => {
            const loadedTemplates = await getAPITemplates();
            setTemplates(loadedTemplates);
        };
        loadTemplates();
    }, []);

    useEffect(() => {
        const savedSettings = JSON.parse(localStorage.getItem('text2video-settings')) || settings;
        const savedPromptRefinerSettings = JSON.parse(localStorage.getItem('prompt-refiner-settings')) || promptRefinerSettings;

        setLocalSettings(savedSettings);
        setLocalPromptRefinerSettings(savedPromptRefinerSettings);
    }, [settings, promptRefinerSettings]);

    useEffect(() => {
        setCurrentTab(initialTab);
    }, [initialTab]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setLocalSettings(prevSettings => ({
            ...prevSettings,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleChangeProvider = (e) => {
        const { name, value } = e.target;
        console.log("handleChangeProvider: ", name, value);

        setLocalPromptRefinerSettings(prevSettings => {
            let updatedSettings = { ...prevSettings, [name]: value };

            if (name === 'provider') {
                const selectedTemplate = templates.find(template => template.name === value);
                if (selectedTemplate) {
                    updatedSettings = {
                        ...updatedSettings,
                        provider: value,
                        base_url: decodeURIComponent(selectedTemplate.url.split('?baseurl=')[1].split('&')[0]),
                        apiKey: value === 'ollama' ? 'ollama' : prevSettings.apiKey
                    };
                }
            }

            if (name === 'provider' || name === 'apiKey' || name === 'base_url') {
                setTimeout(() => fetchModels(updatedSettings), 0);
            }

            return updatedSettings;
        });
    };

    const handleSaveSettings = () => {
        onOpenChange(false);
        setSettings(localSettings);
        setPromptRefinerSettings(localPromptRefinerSettings);

        localStorage.setItem('text2video-settings', JSON.stringify(localSettings));
        localStorage.setItem('prompt-refiner-settings', JSON.stringify(localPromptRefinerSettings));
    };

    const handleGetApiKey = () => {
        if (currentTab === 'text2video') {
            let apiKeyUrl = 'https://fal.ai/dashboard/keys'; // 默认链接，可以根据需要修改
            window.open(apiKeyUrl, '_blank');
        } else {
            const template = templates.find(t => t.name === localPromptRefinerSettings.provider);
            const apiKeyUrl = template ? decodeURIComponent(template.url.split('&apiKeyUrl=')[1].split('&')[0]) : 'https://example.com/get-api-key';
            window.open(apiKeyUrl, '_blank');
        }
    };

    const fetchModels = async (settings = localPromptRefinerSettings) => {
        setIsLoadingModels(true);
        setFetchModelsError(null);
        const { base_url, apiKey, provider } = settings;
        const isOllama = provider === 'ollama' ? '1' : '0';

        try {
            let url;
            if (isOllama === '1') {
                const { protocol, host } = new URL(base_url);
                url = `${protocol}//${host}/api/tags`;
            } else {
                url = `${base_url}/models`;
            }

            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`
                }
            });

            if (!response.ok) {
                const text = await response.text();
                console.error(`HTTP error! status: ${response.status}, text: ${text}`);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
                console.error("Invalid content type, expected application/json");
                throw new Error("Invalid content type, expected application/json");
            }

            const data = await response.json();
            if (isOllama === '1') {
                setModels(data.models.map(model => ({ value: model.name.split(':')[0], label: model.name.split(':')[0] })));
            } else {
                setModels(data.data.map(model => ({ value: model.id, label: model.id })));
            }

        } catch (error) {
            setFetchModelsError("Error fetching models check your apikey: " + error.message);
            setModels([]);
        } finally {
            setIsLoadingModels(false);
        }
    };

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent">
                <Dialog.Title className="DialogTitle">Advanced Settings</Dialog.Title>
                <Tabs.Root className="TabsRoot" value={currentTab} onValueChange={setCurrentTab}>
                    <Tabs.List className="TabsList">
                        <Tabs.Trigger className="TabsTrigger" value="text2video">Text2Video</Tabs.Trigger>
                        <Tabs.Trigger className="TabsTrigger" value="promptRefiner">Prompt Refiner</Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content className="TabsContentDialog" value="text2video">
                        <form>
                            <div className="Fieldset">
                                <label htmlFor="apikey" className="Label">API Key</label>
                                <input
                                    id="apikey"
                                    type="text"
                                    name="apikey"
                                    placeholder="Enter API key here"
                                    value={localSettings.apikey}
                                    onChange={handleChange}
                                    className="Input"
                                />
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="video_size" className="Label">Video Size</label>
                                <select
                                    id="video_size"
                                    name="video_size"
                                    value={localSettings.video_size}
                                    onChange={handleChange}
                                    className="Input"
                                >
                                    <option value="square_hd">Square HD</option>
                                    <option value="portrait_4_3">Portrait 4:3</option>
                                    <option value="landscape_4_3">Landscape 4:3</option>
                                </select>
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="guidance_scale" className="Label">CFG</label>
                                <input
                                    id="guidance_scale"
                                    type="number"
                                    step="0.5"
                                    min="1"
                                    max="20"
                                    name="guidance_scale"
                                    value={localSettings.guidance_scale}
                                    onChange={handleChange}
                                    className="Input"
                                />
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="num_inference_steps" className="Label">Inference Steps</label>
                                <input
                                    id="num_inference_steps"
                                    type="number"
                                    min="1"
                                    name="num_inference_steps"
                                    value={localSettings.num_inference_steps}
                                    onChange={handleChange}
                                    className="Input"
                                />
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="negative_prompt" className="Label">Negative Prompt</label>
                                <input
                                    id="negative_prompt"
                                    type="text"
                                    name="negative_prompt"
                                    value={localSettings.negative_prompt}
                                    onChange={handleChange}
                                    className="Input"
                                />
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="seed" className="Label">Seed</label>
                                <input
                                    id="seed"
                                    type="number"
                                    min="0"
                                    name="seed"
                                    value={localSettings.seed}
                                    onChange={handleChange}
                                    className="Input"
                                />
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="use_rife" className="Label">Use RIFE</label>
                                <input
                                    id="use_rife"
                                    type="checkbox"
                                    name="use_rife"
                                    checked={localSettings.use_rife}
                                    onChange={handleChange}
                                    className="Input"
                                />
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="export_fps" className="Label">Export FPS</label>
                                <input
                                    id="export_fps"
                                    type="number"
                                    min="1"
                                    name="export_fps"
                                    value={localSettings.export_fps}
                                    onChange={handleChange}
                                    className="Input"
                                />
                            </div>
                        </form>
                    </Tabs.Content>
                    <Tabs.Content className="TabsContentDialog" value="promptRefiner">
                        <form>
                            <div className="Fieldset">
                                <label htmlFor="provider" className="Label">Provider</label>
                                <select
                                    id="provider"
                                    name="provider"
                                    value={localPromptRefinerSettings.provider}
                                    onChange={handleChangeProvider}
                                    className="Input"
                                >
                                    {templates.map(template => (
                                        <option key={template.name} value={template.name}>
                                            {template.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="base_url" className="Label">Base URL</label>
                                <input
                                    id="base_url"
                                    type="text"
                                    name="base_url"
                                    placeholder="Enter base URL here"
                                    value={localPromptRefinerSettings.base_url}
                                    onChange={handleChangeProvider}
                                    className="Input"
                                />
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="apiKey" className="Label">API Key</label>
                                <input
                                    id="apiKey"
                                    type="text"
                                    name="apiKey"
                                    placeholder="Enter API key here"
                                    value={localPromptRefinerSettings.apiKey}
                                    onChange={handleChangeProvider}
                                    className="Input"
                                />
                            </div>
                            <div className="Fieldset">
                                <label htmlFor="model" className="Label">Model</label>
                                <input
                                    id="model"
                                    type="text"
                                    name="model"
                                    placeholder="Enter model here"
                                    value={localPromptRefinerSettings.model}
                                    onChange={handleChangeProvider}
                                    className="Input"
                                />
                            </div>
                            <div className="Fieldset relative">
                                <label htmlFor="available_models" className="Label">Available Models</label>
                                <select
                                    id="available_models"
                                    name="available_models"
                                    value={localPromptRefinerSettings.available_models}
                                    onChange={(e) => {
                                        handleChangeProvider(e);
                                        setLocalPromptRefinerSettings(prevSettings => ({
                                            ...prevSettings,
                                            model: e.target.value
                                        }));
                                    }}
                                    className="Input"
                                >
                                    {models.map(model => (
                                        <option key={model.value} value={model.value}>
                                            {model.label}
                                        </option>
                                    ))}
                                </select>
                                <button type="button" title="fetch models" onClick={fetchModels} className="Button absolute right-4 top-1">
                                    {LsIcons.Text2Image_fetach_llm_models}
                                </button>
                            </div>
                            {fetchModelsError && <div>{fetchModelsError}</div>}
                        </form>
                    </Tabs.Content>
                </Tabs.Root>
                <div className="flex justify-end mt-4 gap-4">
                    <Dialog.Close asChild>
                        <button className="Button green" onClick={handleSaveSettings}>
                            Save Changes
                        </button>
                    </Dialog.Close>
                    <button
                        type="button"
                        className="Button violet"
                        onClick={handleGetApiKey}
                    >
                        Get API Key
                    </button>
                </div>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default VideoSettingsDialog;
