import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface SEOConfig {
  title: string;
  description: string;
  canonical: string;
  keywords?: string; // 添加keywords字段
  og: {
    title: string;
    description: string;
    url: string;
    image: string;
    type: string;
  };
}

interface SEOProps {
  title: string;
  description: string;
  url?: string;
  image?: string;
  keywords?: string; // 支持传入keywords
}

const SEO: React.FC<SEOProps> = ({ title, description, url, image, keywords }) => {
  const location = useLocation();
  const [seoConfig, setSeoConfig] = useState<Record<string, SEOConfig> | null>(null);

  useEffect(() => {
    fetch('/seoConfig.json')
      .then(response => response.json())
      .then(config => {
        setSeoConfig(config);
      })
      .catch(error => {
        console.error('Error loading SEO config:', error);
        setSeoConfig(null);
      });
  }, []);

  let seoInfo = {
    title,
    description,
    url: url || `https://llmstock.com${location.pathname}`,
    image,
    keywords, // 初始化为默认值
  };

  if (seoConfig) {
    // 查找匹配的路径配置
    for (const [path, config] of Object.entries(seoConfig)) {
      if (location.pathname.startsWith(`/${path}`)) {
        seoInfo = {
          title: config.title,
          description: config.description,
          url: config.og.url,
          image: config.og.image,
          keywords: config.keywords // 从配置中获取keywords
        };
        break;
      }
    }
  }

  return (
    <Helmet>
      <title>{seoInfo.title}</title>
      <meta name="description" content={seoInfo.description} />
      <meta name="keywords" content={seoInfo.keywords} /> {/* 添加keywords */}
      <link rel="canonical" href={seoInfo.url} />

      <meta property="og:type" content="article" />
      <meta property="og:url" content={seoInfo.url} />
      <meta property="og:title" content={seoInfo.title} />
      <meta property="og:description" content={seoInfo.description} />
      {seoInfo.image && <meta property="og:image" content={seoInfo.image} />}

      <meta name="twitter:site" content="@McQueenFu" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={seoInfo.url} />
      <meta name="twitter:title" content={seoInfo.title} />
      <meta name="twitter:description" content={seoInfo.description} />
      {seoInfo.image && <meta name="twitter:image" content={seoInfo.image} />}
    </Helmet>
  );
};

export default SEO;
