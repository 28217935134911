import React, { useEffect, useRef, memo } from 'react';
import TradingViewWidget from './TradingViewWidget';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import { motion } from 'framer-motion';
import * as Tooltip from '@radix-ui/react-tooltip';
import "../css/market.css"
import SEO from './seo/SEO';

const AnimatedButton = () => (
  <Tooltip.Provider>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <motion.a
          href="https://llmstock.com/specialoffer"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute z-10 p-2 bg-white rounded-full shadow-lg trade-button" // 修改这里
          whileHover={{ scale: 1.1, rotate: 360 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          <motion.img
            src="/icons/dollar-symbol-money.svg"
            alt="Trade"
            className="w-6 h-6"
            initial={{ opacity: 0.6 }}
            animate={{ opacity: 1 }}
            transition={{ yoyo: Infinity, duration: 2 }}
          />
        </motion.a>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content
          className="ms-TooltipContent"
          sideOffset={5}
        >
          Open trade account now
          <Tooltip.Arrow className="ms-TooltipArrow" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
);


const MarketStatus = () => {
  const container = useRef();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Market Status" });
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "colorTheme": "light",
        "dateRange": "12M",
        "showChart": true,
        "locale": "en",
        "largeChartUrl": "",
        "isTransparent": false,
        "showSymbolLogo": true,
        "showFloatingTooltip": false,
        "width": "100%",
        "height": "500",
        "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
        "plotLineColorFalling": "rgba(41, 98, 255, 1)",
        "gridLineColor": "rgba(240, 243, 250, 0)",
        "scaleFontColor": "rgba(19, 23, 34, 1)",
        "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
        "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
        "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
        "tabs": [
          {
            "title": "Indices",
            "symbols": [
              {
                "s": "FOREXCOM:SPXUSD",
                "d": "S&P 500 Index"
              },
              {
                "s": "COMEX:GC1!",
                "d": "Gold feature"
              },
              {
                "s": "CBOE:VX1!",
                "d": "VIX for s&p 500"
              },
              {
                "s": "BINANCE:BTCUSDT",
                "d": "Bitcoin"
              },
              {
                "s": "CAPITALCOM:DXY",
                "d": "dollar index"
              }
            ],
            "originalTitle": "Indices"
          },
          {
            "title": "Futures",
            "symbols": [
              {
                "s": "CME_MINI:ES1!",
                "d": "S&P 500"
              },
              {
                "s": "CME:6E1!",
                "d": "Euro"
              },
              {
                "s": "COMEX:GC1!",
                "d": "Gold"
              },
              {
                "s": "NYMEX:CL1!",
                "d": "WTI Crude Oil"
              },
              {
                "s": "NYMEX:NG1!",
                "d": "Gas"
              },
              {
                "s": "CBOT:ZC1!",
                "d": "Corn"
              }
            ],
            "originalTitle": "Futures"
          },
          {
            "title": "Bonds",
            "symbols": [
              {
                "s": "CBOT:ZB1!",
                "d": "T-Bond"
              },
              {
                "s": "CBOT:UB1!",
                "d": "Ultra T-Bond"
              },
              {
                "s": "EUREX:FGBL1!",
                "d": "Euro Bund"
              },
              {
                "s": "EUREX:FBTP1!",
                "d": "Euro BTP"
              },
              {
                "s": "EUREX:FGBM1!",
                "d": "Euro BOBL"
              }
            ],
            "originalTitle": "Bonds"
          },
          {
            "title": "Forex",
            "symbols": [
              {
                "s": "FX:EURUSD",
                "d": "EUR to USD"
              },
              {
                "s": "FX:GBPUSD",
                "d": "GBP to USD"
              },
              {
                "s": "FX:USDJPY",
                "d": "USD to JPY"
              },
              {
                "s": "FX:USDCHF",
                "d": "USD to CHF"
              },
              {
                "s": "FX:AUDUSD",
                "d": "AUD to USD"
              },
              {
                "s": "FX:USDCAD",
                "d": "USD to CAD"
              }
            ],
            "originalTitle": "Forex"
          }
        ]
      }
    `;
    container.current.appendChild(script);
  }, []);

  const widgets = [
    { type: 'container' },
    { type: 'symbol', symbol: "FOREXCOM:SPXUSD" },
    { type: 'symbol', symbol: "COMEX:GC1!" },
    { type: 'symbol', symbol: "CBOE:VX1!" },
    { type: 'symbol', symbol: "BINANCE:BTCUSDT" },
    { type: 'symbol', symbol: "CAPITALCOM:DXY" }
  ];

  return (
    <>
            <SEO
        title="global finacial market status in one page"
        description="s&p 500 index, gold, vix, bitcoin, dollar index all in one monitor"
        image="https://llmstock.com:2337/uploads/0d94efeb_30e0_4eed_9325_c6d99bf9a78a_d23525f32c.jpg"
      />
    
    <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 p-3 md:p-4 xl:p-5 dark:bg-gray-900">
      {widgets.map((item, index) => (
        <div key={index} className="bg-white border rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 relative" style={{ width: '400px', height: '500px' }}>
          <AnimatedButton />
          <div className="p-2 flex justify-center">
            {item.type === 'container' ? (
              <div className="tradingview-widget-container" ref={container} style={{ height: '100%', width: '100%' }}>
                <div className="tradingview-widget-container__widget"></div>
              </div>
            ) : (
              <TradingViewWidget symbol={item.symbol} height="500" />
            )}
          </div>
        </div>
      ))}
    </div>
    </>
  );
}

export default memo(MarketStatus);
