import React, { useEffect, useRef, useState } from 'react';

const ContentAdComponent = ({ adSlot = '3779714551' }) => {
  const adRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (adRef.current) {
      observer.observe(adRef.current);
    }

    return () => {
      if (adRef.current) {
        observer.unobserve(adRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const initializeAd = () => {
      if (adRef.current && adRef.current.offsetWidth >= 250) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error('Adsense initialization error', e);
        }
      } else {
        console.warn('Ad container width is insufficient');
      }
    };

    const loadAdScript = () => {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6030831640499533';
      script.async = true;
      script.onload = initializeAd;
      document.head.appendChild(script);
    };

    if (window.adsbygoogle) {
      initializeAd();
    } else {
      loadAdScript();
    }
  }, [isVisible, adSlot]);

  return (
    <div
      ref={adRef}
      className="ad-container"
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100px',
        minWidth: '250px',
        margin: '20px 0',
        textAlign: 'center',
      }}
    >
      {isVisible && (
        <ins
          className="adsbygoogle"
          style={{ display: 'block', width: '100%', height: '100%' }}
          data-ad-client="ca-pub-6030831640499533"
          data-ad-slot={adSlot}
          data-ad-format="fluid"
          data-full-width-responsive="true"
        />
      )}
    </div>
  );
};

export default ContentAdComponent;
