// SkeletonPostCard.js
import React from 'react';

const SkeletonPostCard = () => {
    return (
        <div className="post-card skeleton">
            <div className="skeleton-image"></div>
            <div className="post-card-content">
                <div className="skeleton-title"></div>
                <div className="skeleton-text"></div>
                <div className="skeleton-text"></div>
            </div>
        </div>
    );
};

export default SkeletonPostCard;
