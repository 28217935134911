// TigerSecuritiesPromo.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import '../css/TigerSecuritiesPromo.css';

const currencySymbols = ['$', '€', '£', '¥', '₹', '₽', '₿', '₱', '₺', '₫'];

function CurrencyRain() {
    const cloudRef = useRef(null);

    useEffect(() => {
        const randomCurrency = () => {
            return currencySymbols[Math.floor(Math.random() * currencySymbols.length)];
        };

        const rain = () => {
            if (!cloudRef.current) return;

            let e = document.createElement('div');
            e.classList.add('drop');
            cloudRef.current.appendChild(e);

            let left = Math.floor(Math.random() * cloudRef.current.clientWidth);
            let size = Math.random() * 1.5;
            let duration = Math.random() * 1;

            e.innerText = randomCurrency();
            e.style.left = left + 'px';
            e.style.fontSize = 0.5 + size + 'em';
            e.style.animationDuration = 1 + duration + 's';

            setTimeout(() => {
                if (cloudRef.current) cloudRef.current.removeChild(e);
            }, 2000);
        };

        const interval = setInterval(rain, 20);

        return () => clearInterval(interval);
    }, []);

    return <div ref={cloudRef} className="cloud"></div>;
}

function RegionSelector({ regions, onSelect, selectedRegion }) {
    return (
        <div className="region-selector">
            {regions.map((region) => (
                <button
                    key={region.id}
                    className={`region-card ${selectedRegion === region.region ? 'active' : ''}`}
                    onClick={() => onSelect(region.region)}
                >
                    <span className="flag">{region.flag}</span>
                    <span className="name">{region.name}</span>
                </button>
            ))}
        </div>
    );
}

function ContentArea({ content }) {
    const imageUrl = content.image
        ? `${process.env.REACT_APP_CMS_API_BASE_URL}${content.image.url}`
        : '';

    return (
        <div className="content-area">
            <div className="content-card">
                {imageUrl && <img src={imageUrl} alt={`${content.region} promotion`} className="promo-image" />}
                <div className="post-markdown-offer">
                    <ReactMarkdown>{content.description || ''}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
}

function SignUpButton({ buttonText }) {
    return (
        <a
            href="https://www.tigersecurities.com/signup?source=inner&invite=LLMSTOCK"
            className="sign-up-button"
            target="_blank"
            rel="noopener noreferrer"
        >
            {buttonText}
        </a>
    );
}

function TigerSecuritiesPromo() {
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [contents, setContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios.get('https://llmstock.com:2337/tgoffers')
            .then(response => {
                const data = response.data;
                setRegions(data);
                setContents(data);
                const userLanguage = navigator.language || navigator.userLanguage;
                const defaultRegion = userLanguage.toLowerCase().startsWith('zh') ? 'china' : 'singapore';
                const defaultContent = data.find(item => item.region === defaultRegion) || data[0];
                setSelectedRegion(defaultContent.region);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    }, []);

    const handleRegionSelect = (region) => {
        setSelectedRegion(region);
    };

    if (isLoading) return <div className="loading">Loading...</div>;
    if (contents.length === 0) return <div className="no-content">No content available</div>;

    const selectedContent = contents.find(item => item.region === selectedRegion) || contents[0];

    return (
        <div className="tiger-securities-promo">
            <div className="currency-rain-container">
                <CurrencyRain/>
            </div>
            <RegionSelector
                regions={regions}
                onSelect={handleRegionSelect}
                selectedRegion={selectedRegion}
            />
            <SignUpButton buttonText={selectedContent.buttontext}/>
            <ContentArea content={selectedContent}/>
        </div>
    );
}

export default TigerSecuritiesPromo;
