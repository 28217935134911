import React from 'react';
import { Link } from 'react-router-dom';
import '../css/post-card.css';

const PostCard = ({ post }) => {
  const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;
  const defaultImageUrl = '/images/default_blog.jpg';

  return (
    <Link to={`/post/${post.id}`} className="block post-card">
      <div className="card-container">
        <div className="post-card-img-container">
          <img
            src={post.cover && post.cover.length > 0 ? `${apiUrl}${post.cover[0].url}` : defaultImageUrl}
            alt={post.title}
            className="post-card-img"
          />
        </div>
        <div className="post-card-content">
          <h2 className="post-card-title">{post.title}</h2>
          <p className="post-card-description">{post.description}</p>
          <p className="post-card-date">{new Date(post.date).toLocaleDateString()}</p>
        </div>
      </div>
    </Link>
  );
};

export default PostCard;
