import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryFilter from './CategoryFilter';
import PostsList from './PostsList';
import '../css/blog.css';
import SEO from './seo/SEO';

const Blog = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const graphqlQuery = `
          query {
            categories {
              id
              name
            }
          }
        `;

        const response = await axios.post(`${apiUrl}/graphql`, {
          query: graphqlQuery
        });

        if (response.data.data && response.data.data.categories) {
          setCategories(response.data.data.categories);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [apiUrl]);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId === 'all' ? "all" : categoryId);
  };

  return (
      <>
        <SEO
            title="AI, stock market, technology related news and blog"
            description="ai,stock,llm,aigc,stock market,technology,game,artificial intelligence related news and blogs"
        />
        <div className="container mx-auto p-4">
          <div className="category-filter-card bg-white shadow-lg rounded-lg p-4 mb-6 transition-transform transform hover:translate-y-[-10px] hover:shadow-xl">
            <CategoryFilter
                categories={categories}
                selectedCategory={selectedCategory}
                onCategoryChange={handleCategoryChange}
            />
          </div>
          <PostsList
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={handleCategoryChange}
          />
        </div>
      </>
  );
};

export default Blog;
