import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PostCard from './PostCard';
import SkeletonPostCard from './SkeletonPostCard';
import ReactGA from "react-ga4";
import '../css/post-list.css';
import { PinLeftIcon, PinRightIcon, ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import FeedAdComponent from './FeedAdComponent';

const PostsList = ({ categories, selectedCategory, onCategoryChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState(true);
  const postsPerPage = 9;
  const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;
  const containerRef = useRef(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Posts List" });
  }, [location.pathname]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(searchParams.get('page')) || 1;
    const categoryFromUrl = searchParams.get('category') || 'all';
    setPage(pageFromUrl);
    if (typeof onCategoryChange === 'function') {
      onCategoryChange(categoryFromUrl);
    }
    fetchTotalPages(categoryFromUrl);
  }, [location.search, onCategoryChange]);

  useEffect(() => {
    if (totalPages > 0) {
      let targetPage = page;
      if (page > totalPages) {
        targetPage = totalPages;
      } else if (page < 1) {
        targetPage = 1;
      }
      if (targetPage !== page) {
        setPage(targetPage);
        updateUrl(targetPage, selectedCategory);
      } else {
        fetchPosts();
      }
    }
  }, [page, totalPages, selectedCategory]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateUrl = (page, category) => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', page);
    if (category && category !== 'all') {
      searchParams.set('category', category);
    }
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const fetchTotalPages = async (category) => {
    try {
      const query = `
        query {
          postsConnection(where: ${category && category !== 'all' ? `{ category: { id: "${category}" } }` : '{}'}) {
            aggregate {
              count
            }
          }
        }
      `;

      const response = await axios.post(`${apiUrl}/graphql`, { query });
      const totalPosts = response.data.data.postsConnection.aggregate.count;
      const calculatedTotalPages = Math.ceil(totalPosts / postsPerPage);
      setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.error('Error fetching total posts count:', error);
    }
  };

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const query = `
        query($start: Int!, $limit: Int!, ${selectedCategory && selectedCategory !== 'all' ? '$categoryId: ID!' : ''}) {
          posts(
            start: $start,
            limit: $limit,
            sort: "id:DESC"
            ${selectedCategory && selectedCategory !== 'all' ? 'where: { category: { id: $categoryId } }' : ''}
          ) {
            id
            title
            description
            slug
            date
            category {
              id
              name
            }
            cover {
              url
            }
          }
        }
      `;

      const variables = {
        start: (page - 1) * postsPerPage,
        limit: postsPerPage,
        ...(selectedCategory && selectedCategory !== 'all' && { categoryId: selectedCategory }),
      };

      const response = await axios.post(`${apiUrl}/graphql`, { query, variables });
      setPosts(response.data.data.posts);
      setHasMore(response.data.data.posts.length === postsPerPage);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setLoading(false);
    }
  };

  const loadPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages && pageNumber !== page) {
      updateUrl(pageNumber, selectedCategory);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = isMobile ? 3 : 5;
    let startPage = Math.max(1, page - Math.floor(maxPageNumbersToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    if (endPage - startPage + 1 < maxPageNumbersToShow) {
      startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
          <button
              key={i}
              onClick={() => loadPage(i)}
              className={`nav-button ${i === page ? 'active' : ''}`}
          >
            {i}
          </button>
      );
    }

    return pageNumbers;
  };

  return (
      <div className="posts-container" ref={containerRef}>
        <div className="posts-grid">
        {posts.map((post, index) => (
          <React.Fragment key={post.id}>
            <PostCard post={post} />
            {(index) % 4 === 0 && <FeedAdComponent index={Math.floor(index / 4)}/>}
          </React.Fragment>
        ))}
        {loading && Array(postsPerPage).fill().map((_, index) => (
          <SkeletonPostCard key={`skeleton-${index}`} />
        ))}
      </div>
        {totalPages > 1 && (
            <div className="pagination-container">
              <div className="nav-button-group">
                <button
                    onClick={() => loadPage(1)}
                    className="nav-button"
                    disabled={page === 1}
                >
                  <PinLeftIcon />
                </button>
                <button
                    onClick={() => loadPage(page - 1)}
                    className="nav-button"
                    disabled={page === 1}
                >
                  <ArrowLeftIcon />
                </button>
                {renderPageNumbers()}
                <button
                    onClick={() => loadPage(page + 1)}
                    className="nav-button"
                    disabled={page === totalPages || !hasMore}
                >
                  <ArrowRightIcon />
                </button>
                <button
                    onClick={() => loadPage(totalPages)}
                    className="nav-button"
                    disabled={page === totalPages}
                >
                  <PinRightIcon />
                </button>
              </div>
            </div>
        )}
      </div>
  );
};

export default PostsList;
