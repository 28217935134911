import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TradingViewWidget from './TradingViewWidget';
import StockSearch from './StockSearch';
import { LsIcons } from "@/components/ui/LsIcons";
import * as Tooltip from '@radix-ui/react-tooltip';
import "../css/tooltip.css"
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import "../css/market.css"
import SEO from './seo/SEO';

const Dashboard = () => {
  const location = useLocation();
  ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Dashboard" });
  const { dashboardId } = useParams();
  const [dashboard, setDashboard] = useState(null);
  const [tickers, setTickers] = useState([]);
  const [error, setError] = useState(null); // New state variable
  const navigate = useNavigate();

  const AnimatedButton = () => (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <motion.a
            href="https://llmstock.com/specialoffer"
            target="_blank"
            rel="noopener noreferrer"
            className="absolute z-10 p-2 bg-white rounded-full shadow-lg trade-button" // 修改这里
            whileHover={{ scale: 1.1, rotate: 360 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
          >
            <motion.img
              src="/icons/dollar-symbol-money.svg"
              alt="Trade"
              className="w-6 h-6"
              initial={{ opacity: 0.6 }}
              animate={{ opacity: 1 }}
              transition={{ yoyo: Infinity, duration: 2 }}
            />
          </motion.a>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="ms-TooltipContent"
            sideOffset={5}
          >
            Open trade account now
            <Tooltip.Arrow className="ms-TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
  

  useEffect(() => {
    const fetchDashboard = async () => {
      console.log('Fetching dashboard with id:', dashboardId); // Debug output
      //const siteAccessToken = localStorage.getItem('accessToken');
      const response = await fetch('/api/getdashboard', {
        method: 'POST',
        headers: {
          //'Authorization': `Bearer ${siteAccessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ did: dashboardId })
      });

      if (!response.ok) {
        if (response.status === 401) {
          //navigate('/login');
        }
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Fetched dashboard data:', data); // Debug output
      setDashboard(data);
      setTickers(data.tickets);
    };

    fetchDashboard();
  }, [dashboardId, navigate]);

  const handleAddTicker = async (ticker) => {
    const luserId = localStorage.getItem('luserid');
    const siteAccessToken = localStorage.getItem('accessToken');
    const response = await fetch('/api/addticketodashboard', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${siteAccessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        luserid: luserId,
        did: dashboardId,
        ticket: ticker
      })
    });

    if (!response.ok) {
      if (response.status === 401) {
        //navigate('/login');
        setError('Please login.'); // Set error message
      } else if (response.status === 403) {
        setError('You do not have permission to perform this action may be not your dashboard.'); // Set error message
      }
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    setDashboard(data);
    setTickers(data.tickets);
    setError(null); // Clear error message
  };

  const handleRemoveTicker = async (ticker) => {
    const luserId = localStorage.getItem('luserid');
    const siteAccessToken = localStorage.getItem('accessToken');
    const response = await fetch('/api/removeticketodashboard', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${siteAccessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        luserid: luserId,
        did: dashboardId,
        ticket: ticker
      })
    });

    if (!response.ok) {
      if (response.status === 401) {
        //navigate('/login');
        setError('Please login.'); // Set error message
      } else if (response.status === 403) {
        setError('You do not have permission to perform this action mybe not your dashboard.'); // Set error message
      }
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    setDashboard(data);
    setTickers(data.tickets);
    setError(null); // Clear error message
  };

  return (
    <>
            <SEO
        title="dashboard for stocks and featues"
        description="watch and monitor your favorite stocks and features in one page"

      />
    <Tooltip.Provider>
      <div className="dashboard-page p-5">
        <h2 className="text-2xl font-bold mb-4">{dashboard?.name}</h2>
        {error && (
          <div className="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="add-ticker m-4">
          <StockSearch onSetStock={handleAddTicker} buttonText="Add stock"/>
        </div>
        <div className="tickers-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {tickers.map((ticker, index) => (
            <div key={index} className="relative ticker-item bg-white rounded-lg shadow-md p-5">
              <AnimatedButton />
              <TradingViewWidget symbol={ticker} height="400" />
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-bold text-xl">{ticker}</h3>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <button onClick={() => handleRemoveTicker(ticker)} className="mr-2 bg-gray-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                      {LsIcons.Remove_svg_icon}
                    </button>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content className="TooltipContent" side="top">
                      <Tooltip.Arrow className="TooltipArrow" />
                      Delete Ticker
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Tooltip.Provider>
    </>
  );
};

export default Dashboard;
