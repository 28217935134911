import React, { memo, useEffect, useRef, useState, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { LsIcons } from './ui/LsIcons';
import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import 'highlight.js/styles/a11y-dark.css';
import useTextToSpeech from './hooks/useTextToSpeech';
import CodePre from './ui/CodePre';
import debounce from 'lodash/debounce';

const CodeRenderer = memo(({ node, inline, className = "blog-code", children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
        <SyntaxHighlighter
            style={a11yDark}
            language={match[1]}
            PreTag="div"
            {...props}
        >
            {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
    ) : (
        <code className={className} {...props}>
            {children}
        </code>
    );
}, (prevProps, nextProps) => prevProps.children === nextProps.children);

const MessageList = memo(({ messages, handleCopyMessage, handleCopyCode }) => {
    const messagesEndRef = useRef(null);
    const [currentlySpeaking, setCurrentlySpeaking] = useState(null);
    const { speak, stopSpeak } = useTextToSpeech(() => setCurrentlySpeaking(null));

    const handleSpeakClick = (message, index) => {
        speak(message.content);
        setCurrentlySpeaking(index);
    };

    const handleStopClick = () => {
        stopSpeak();
        setCurrentlySpeaking(null);
    };

    const debouncedScroll = useCallback(debounce(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, 100), []);

    useEffect(() => {
        debouncedScroll();
        return () => {
            debouncedScroll.cancel();
        };
    }, [messages, debouncedScroll]);

    return (
        <div className="flex-grow overflow-y-auto mb-32" style={{
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #f1f1f1',
            '&::-webkit-scrollbar': {
                width: '4px',
                height: '4px',
            },
            '&::-webkit-scrollbar-track': {
                background: 'rgba(241, 241, 241, 0.5)',
                borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: 'rgba(136, 136, 136, 0.8)',
                borderRadius: '4px',
                boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: 'rgba(85, 85, 85, 0.9)',
                boxShadow: '0 0 6px rgba(0, 0, 0, 0.3)',
            },
        }}>
            {messages.map((message, index) => {
                const isUser = message.role === 'user';
                const isSystem = message.role === 'lsystem';
                return (
                    <div key={index} className={`my-2 flex flex-col items-start ${isUser ? 'items-end' : ''}`}>
                        <div className={`flex items-center ${isUser ? 'ml-4' : 'mr-4'}`}>
                            {isUser ? (
                                <span className="text-gray-500">👤</span>
                            ) : (
                                <span className="text-blue-100">🤖</span>
                            )}
                        </div>
                        <div className={`p-3 rounded-lg ${isUser ? 'bg-gray-200 text-black' : 'bg-blue-50 text-black'}`}>
                            {isUser || isSystem ? (
                                <code>{message.content}</code>
                            ) : (
                                <ReactMarkdown
                                    className="post-markdown"
                                    linkTarget="_blank"
                                    rehypePlugins={[rehypeRaw]}
                                    remarkPlugins={[remarkGfm]}
                                    components={{
                                        pre: CodePre,
                                        code: CodeRenderer,
                                    }}
                                >
                                    {message.content}
                                </ReactMarkdown>
                            )}
                        </div>
                        <div className="flex flex-grow flex-wrap gap-1 justify-start mb-2">
                            <button title="copy" onClick={() => handleCopyMessage(message.content)} className="copy-button">{LsIcons.Chat_copy_svg_icon}</button>
                            {currentlySpeaking === index ? (
                                <button onClick={handleStopClick}>{LsIcons.Message_stopSpeak_svg_icon}</button>
                            ) : (
                                <button onClick={() => handleSpeakClick(message, index)}>{LsIcons.Message_speak_svg_icon}</button>
                            )}
                        </div>
                    </div>
                );
            })}
            <div ref={messagesEndRef}></div>
        </div>
    );
}, (prevProps, nextProps) => prevProps.messages === nextProps.messages);

export default MessageList;
