import React, { useRef, useState } from 'react';
import { motion, useMotionValue, useSpring, useTransform, AnimatePresence } from "framer-motion";
import { useNavigate } from 'react-router-dom';

const IconContainer = ({ mouseX, template, onClick }) => {
  let ref = useRef(null);
  const [hovered, setHovered] = useState(false);

  let distance = useTransform(mouseX, (val) => {
    let bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };
    return val - bounds.x - bounds.width / 2;
  });

  let widthTransform = useTransform(distance, [-150, 0, 150], [40, 60, 40]);
  let heightTransform = useTransform(distance, [-150, 0, 150], [40, 60, 40]);

  let width = useSpring(widthTransform, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });
  let height = useSpring(heightTransform, {
    mass: 0.1,
    stiffness: 150,
    damping: 12,
  });

  return (
    <motion.div
      ref={ref}
      style={{ width, height }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      className="aspect-square rounded-full bg-gray-200 dark:bg-neutral-800 flex items-center justify-center relative cursor-pointer"
    >
      <AnimatePresence>
        {hovered && (
          <motion.div
            initial={{ opacity: 0, y: 10, x: "-50%" }}
            animate={{ opacity: 1, y: 0, x: "-50%" }}
            exit={{ opacity: 0, y: 2, x: "-50%" }}
            className="px-2 py-0.5 whitespace-pre rounded-md bg-gray-100 border dark:bg-neutral-800 dark:border-neutral-900 dark:text-white border-gray-200 text-neutral-700 absolute left-1/2 -translate-x-1/2 -top-8 w-fit text-xs"
          >
            {template.name}
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        style={{ width: 40, height: 40 }}
        className="flex items-center justify-center"
      >
        {typeof template.icon === 'string' ? (
          <img src={template.icon} alt={template.name} className="w-full h-full object-contain" />
        ) : (
          template.icon
        )}
      </motion.div>
    </motion.div>
  );
};

export const AnimatedDock = ({ items, className }) => {
  const navigate = useNavigate();
  let mouseX = useMotionValue(Infinity);

  const handleClick = (url) => {
    navigate(url);
  };

  return (
    <div className={`${className} flex justify-center w-full`}>
      <motion.div
        onMouseMove={(e) => mouseX.set(e.pageX)}
        onMouseLeave={() => mouseX.set(Infinity)}
        className="flex flex-wrap justify-center w-full gap-2 md:gap-4 items-center rounded-2xl bg-gray-50 dark:bg-neutral-900 p-2 md:p-4"
      >
        {items.map((item) => (
          <IconContainer
            key={item.name}
            mouseX={mouseX}
            template={item}
            onClick={() => handleClick(item.url)}
          />
        ))}
      </motion.div>
    </div>
  );
};
