import React, { useState, useRef, useEffect } from 'react';
import ReactCompareImage from 'react-compare-image';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";
import FalImageUploader from './FalImageUploader';
import FalApiKeyDialog from './FalApiKeyDialog';
import { LsIcons } from './ui/LsIcons';
import * as fal from "@fal-ai/serverless-client";
import "../css/outpaint.css"

const DragHandle = ({ side, onMouseDown, onTouchStart }) => (
  <div
    className="absolute w-8 h-8 cursor-move touch-action-none transition-all duration-200 ease-in-out"
    style={{
      [side === 'left' || side === 'right' ? 'top' : 'left']: '50%',
      [side]: '-16px',
      transform:
        side === 'left' ? 'translate(-50%, -50%) rotate(-45deg)' :
        side === 'right' ? 'translate(50%, -50%) rotate(-45deg)' :
        side === 'top' ? 'translate(-50%, -50%) rotate(-45deg)' :
        side === 'bottom' ? 'translate(-50%, 50%) rotate(-45deg)' :
        'translate(-50%, -50%) rotate(-45deg)',
    }}
    onMouseDown={onMouseDown}
    onTouchStart={onTouchStart}
  >
    <div className="w-full h-full bg-blue-500 rounded-md shadow-lg flex items-center justify-center transform hover:scale-110 hover:bg-blue-600">
      <div className="w-3 h-3 bg-white rounded-full animate-pulse"></div>
    </div>
  </div>
);


const ImageOutpaint = () => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ left: 0, right: 0, top: 0, bottom: 0 });
  const [scale, setScale] = useState(1);
  const [maskImage, setMaskImage] = useState(null);
  const [controlImage, setControlImage] = useState(null);
  const [outpaintedImage, setOutpaintedImage] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [isApiKeyDialogOpen, setIsApiKeyDialogOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const containerRef = useRef(null);
  const isDraggingRef = useRef(false);
  const fileInputRef = useRef(null);
  const uploadedFileRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState({ left: false, right: false });
  const [imageSizeTip, setImageSizeTip] = useState('');

  const CANVAS_SIZE = 1920;
  const PADDING = 40;

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseEvent);
      document.removeEventListener('mouseup', handleMouseEvent);
      document.removeEventListener('touchmove', handleTouchEvent);
      document.removeEventListener('touchend', handleTouchEvent);
    };
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "imageoutpaint" });
    const savedApiKey = localStorage.getItem('falApiKey');
    if (savedApiKey) {
      setApiKey(savedApiKey);
    }
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth - PADDING * 2;
      setScale(containerWidth / CANVAS_SIZE);
    }
  }, []);

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        let newWidth = img.width;
        let newHeight = img.height;
        let resized = false;
  
        if (newWidth > 1280 || newHeight > 1280) {
          const aspectRatio = newWidth / newHeight;
          if (newWidth > newHeight) {
            newWidth = 1280;
            newHeight = Math.round(1280 / aspectRatio);
          } else {
            newHeight = 1280;
            newWidth = Math.round(1280 * aspectRatio);
          }
          resized = true;
        }
  
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
  
        setImage({
          src: canvas.toDataURL('image/png'),
          width: newWidth,
          height: newHeight,
        });
        setCrop({ left: 0, right: 0, top: 0, bottom: 0 });
        setMaskImage(null);
        setControlImage(null);
        setOutpaintedImage(null);
  
        if (resized) {
          setImageSizeTip(`Image has been resized to ${newWidth}x${newHeight} for better outpainting results.`);
        } else {
          setImageSizeTip('');
        }
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(file);
    uploadedFileRef.current = file;
  };

  const handleDrag = (side, delta) => {
    setCrop((prev) => {
      const newCrop = { ...prev };
      switch (side) {
        case 'left':
          newCrop.left = Math.max(0, prev.left - delta / scale);
          break;
        case 'right':
          newCrop.right = Math.max(0, prev.right + delta / scale);
          break;
        case 'top':
          newCrop.top = Math.max(0, prev.top - delta / scale);
          break;
        case 'bottom':
          newCrop.bottom = Math.max(0, prev.bottom + delta / scale);
          break;
      }
      return newCrop;
    });
  };

  const calculateExpansion = () => {
    return {
      left: Math.round(crop.left),
      right: Math.round(crop.right),
      top: Math.round(crop.top),
      bottom: Math.round(crop.bottom),
    };
  };

  // const handleMouseEvent = (side, start) => {
  //   isDraggingRef.current = true;
  //   const moveHandler = (moveEvent) => {
  //     if (!isDraggingRef.current) return;
  //     const delta = (side === 'left' || side === 'right')
  //       ? moveEvent.clientX - start
  //       : moveEvent.clientY - start;
  //     handleDrag(side, delta);
  //     start = (side === 'left' || side === 'right') ? moveEvent.clientX : moveEvent.clientY;
  //   };
  //   const upHandler = () => {
  //     isDraggingRef.current = false;
  //     document.removeEventListener('mousemove', moveHandler);
  //     document.removeEventListener('mouseup', upHandler);
  //   };
  //   document.addEventListener('mousemove', moveHandler);
  //   document.addEventListener('mouseup', upHandler);
  // };

  const handleMouseEvent = (side, start) => {
    isDraggingRef.current = true;
    const moveHandler = (moveEvent) => {
      if (!isDraggingRef.current) return;
      const delta = (side === 'left' || side === 'right')
        ? moveEvent.clientX - start
        : moveEvent.clientY - start;
      handleDrag(side, delta);
      start = (side === 'left' || side === 'right') ? moveEvent.clientX : moveEvent.clientY;
    };
    const upHandler = () => {
      isDraggingRef.current = false;
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mouseup', upHandler);
      document.removeEventListener('touchmove', moveHandler);
      document.removeEventListener('touchend', upHandler);
    };
    document.addEventListener('mousemove', moveHandler);
    document.addEventListener('mouseup', upHandler);
    document.addEventListener('touchmove', moveHandler);
    document.addEventListener('touchend', upHandler);
  };

  const handleTouchEvent = (side, start) => {
    isDraggingRef.current = true;
    const moveHandler = (moveEvent) => {
      if (!isDraggingRef.current) return;
      const touch = moveEvent.touches[0];
      const delta = (side === 'left' || side === 'right')
        ? touch.clientX - start
        : touch.clientY - start;
      handleDrag(side, delta);
      start = (side === 'left' || side === 'right') ? touch.clientX : touch.clientY;
    };
    const endHandler = () => {
      isDraggingRef.current = false;
      document.removeEventListener('touchmove', moveHandler);
      document.removeEventListener('touchend', endHandler);
    };
    document.addEventListener('touchmove', moveHandler, { passive: false });
    document.addEventListener('touchend', endHandler);
  };

  const getImageStyle = () => {
    if (!image) return {};
    const imageAspectRatio = image.width / image.height;
    const canvasAspectRatio = 1;
    let width, height;
    if (imageAspectRatio > canvasAspectRatio) {
      width = Math.min(image.width, CANVAS_SIZE);
      height = width / imageAspectRatio;
    } else {
      height = Math.min(image.height, CANVAS_SIZE);
      width = height * imageAspectRatio;
    }
    return {
      width: `${(width / CANVAS_SIZE) * 100}%`,
      height: `${(height / CANVAS_SIZE) * 100}%`,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    };
  };

  const resetCrop = () => {
    setCrop({ left: 0, right: 0, top: 0, bottom: 0 });
  };

  const generateMasks = () => {
    if (!image) return;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = image.width + crop.left + crop.right;
    canvas.height = image.height + crop.top + crop.bottom;

    // Generate mask image
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const featherSize = 20; // Half of the total feather size (40px)

    // Create a temporary canvas for the feathered black rectangle
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = image.width;
    tempCanvas.height = image.height;

    // Draw the black rectangle on the temporary canvas
    tempCtx.fillStyle = 'black';
    tempCtx.fillRect(featherSize, featherSize, image.width - featherSize * 2, image.height - featherSize * 2);

    // Function to create and apply linear gradient
    const applyLinearGradient = (x, y, w, h, side) => {
      let gradient;
      if (side === 'left') {
        gradient = tempCtx.createLinearGradient(x, y, x + w, y);
      } else if (side === 'right') {
        gradient = tempCtx.createLinearGradient(x + w, y, x, y);
      } else if (side === 'top') {
        gradient = tempCtx.createLinearGradient(x, y, x, y + h);
      } else if (side === 'bottom') {
        gradient = tempCtx.createLinearGradient(x, y + h, x, y);
      }
      gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
      gradient.addColorStop(1, 'rgba(0, 0, 0, 1)');
      tempCtx.fillStyle = gradient;
      tempCtx.fillRect(x, y, w, h);
    };

    // Apply linear gradients to the edges
    applyLinearGradient(0, featherSize, featherSize, image.height - featherSize * 2, "left"); // Left
    applyLinearGradient(image.width - featherSize, featherSize, featherSize, image.height - featherSize * 2, "right"); // Right
    applyLinearGradient(featherSize, 0, image.width - 2 * featherSize, featherSize, "top"); // Top
    applyLinearGradient(featherSize, image.height - featherSize, image.width - 2 * featherSize, featherSize, "bottom"); // Bottom

    // Draw the feathered black rectangle onto the main canvas
    ctx.drawImage(tempCanvas, crop.left, crop.top);

    setMaskImage(canvas.toDataURL());

    // Generate control image
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'gray';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, crop.left, crop.top);
      setControlImage(canvas.toDataURL());
    };
    img.src = image.src;
  };

  const performOutpaint = async () => {
    if (!image || !maskImage || !controlImage || !apiKey) {
      alert('Please upload an image, generate masks, and set an API key before outpainting.');
      return;
    }

    setIsProcessing(true);
    setProgress(0);

    try {
      fal.config({ credentials: apiKey });

      const maskImageFile = await fetch(maskImage).then(res => res.blob());
      const controlImageFile = await fetch(controlImage).then(res => res.blob());
      const maskImageUrl = await fal.storage.upload(maskImageFile);
      const controlImageUrl = await fal.storage.upload(controlImageFile);

      const result = await fal.subscribe("fal-ai/flux-general", {
        input: {
          prompt: prompt,
          image_size: {
            width: image.width + crop.left + crop.right,
            height: image.height + crop.top + crop.bottom
          },
          num_images: 1,
          controlnets: [
            {
              path: "alimama-creative/FLUX.1-dev-Controlnet-Inpainting-Beta",
              mask_image_url: maskImageUrl,
              mask_threshold: 0.4,
              control_image_url: controlImageUrl,
              conditioning_scale: 0.9
            }
          ],
          guidance_scale: 3.5,
          real_cfg_scale: 3.5,
          num_inference_steps: 28,
          enable_safety_checker: true
        },
        logs: true,
        onQueueUpdate: (update) => {
          if (update.status === "IN_PROGRESS") {
            update.logs.map((log) => log.message).forEach(console.log);
            // 更新进度
            setProgress(Math.min((update.logs.length / 28) * 100, 100));
          }
        },
      });

      setOutpaintedImage(result.images[0].url);
      setImagesLoaded({ left: false, right: false });
    } catch (error) {
      console.error("Error performing outpaint:", error);
      alert('An error occurred during outpainting. Please try again.');
    } finally {
      setIsProcessing(false);
      setProgress(0);
    }
  };

  const handleImageLoad = (side) => {
    setImagesLoaded((prev) => ({ ...prev, [side]: true }));
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      .spinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        margin-right: 8px;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <Helmet>
        <title>Outpaint image with flux model</title>
        <meta name="description" content="Use our advanced AI-powered image outpaint tool to easily expand your images. Perfect for creative projects, graphic design, and more." />
      </Helmet>
      <h1 className="text-4xl font-bold text-center mb-8">Image Outpaint Tool</h1>

      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6">
        <FalImageUploader onImageUpload={handleImageUpload} fileInputRef={fileInputRef} />

        {image && (
          <div className="mt-6">
            {imageSizeTip && (
      <div className="mb-4 p-2 bg-blue-100 text-blue-700 rounded">
        {imageSizeTip}
      </div>
    )}
            <div
              ref={containerRef}
              className="relative w-full pt-[100%] border border-gray-300 overflow-hidden rounded-lg"
            >
              <div className="absolute inset-[40px]">
                <img
                  src={image.src}
                  alt="Uploaded"
                  style={getImageStyle()}
                  className="absolute"
                />
                <div
                  style={{
                    position: 'absolute',
                    left: `${((CANVAS_SIZE - image.width) / 2 - crop.left) / CANVAS_SIZE * 100}%`,
                    top: `${((CANVAS_SIZE - image.height) / 2 - crop.top) / CANVAS_SIZE * 100}%`,
                    width: `${(image.width + crop.left + crop.right) / CANVAS_SIZE * 100}%`,
                    height: `${(image.height + crop.top + crop.bottom) / CANVAS_SIZE * 100}%`,
                    border: '2px solid blue',
                    boxSizing: 'border-box',
                  }}
                >
                  {['left', 'right', 'top', 'bottom'].map((side) => (
                    <React.Fragment key={side}>
                      <div
                        style={{
                          position: 'absolute',
                          [side]: '-5px',
                          [side === 'left' || side === 'right' ? 'top' : 'left']: 0,
                          width: side === 'left' || side === 'right' ? '10px' : '100%',
                          height: side === 'top' || side === 'bottom' ? '10px' : '100%',
                          cursor: side === 'left' || side === 'right' ? 'ew-resize' : 'ns-resize',
                          backgroundColor: 'rgba(0, 0, 255, 0.3)',
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          handleMouseEvent(side, side === 'left' || side === 'right' ? e.clientX : e.clientY);
                        }}
                        onTouchStart={(e) => {
                          e.preventDefault();
                          const touch = e.touches[0];
                          handleTouchEvent(side, side === 'left' || side === 'right' ? touch.clientX : touch.clientY);
                        }}
                      />
                      <DragHandle
                        side={side}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          handleMouseEvent(side, side === 'left' || side === 'right' ? e.clientX : e.clientY);
                        }}
                        onTouchStart={(e) => {
                          e.preventDefault();
                          const touch = e.touches[0];
                          handleTouchEvent(side, side === 'left' || side === 'right' ? touch.clientX : touch.clientY);
                        }}
                      />
                    </React.Fragment>
                  ))}
                </div>

              </div>
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Prompt</label>
              <input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Enter prompt for outpainting"
              />
            </div>

            <div className="mt-4 flex justify-center space-x-4">
              <button
                onClick={resetCrop}
                className="px-4 py-2 bg-gray-200 text-black rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                Reset Crop
              </button>
              <button
                onClick={generateMasks}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Generate Mask
              </button>
              <button
                onClick={performOutpaint}
                disabled={isProcessing || !maskImage || !controlImage}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 flex items-center justify-center"
              >
                {isProcessing ? (
                  <>
                    <div className="spinner"></div>
                    Processing...
                  </>
                ) : (
                  'Outpaint'
                )}
              </button>
              <button
                onClick={() => setIsApiKeyDialogOpen(true)}
                className="px-2 py-2 bg-gray-200 text-black rounded hover:bg-gray-300"
              >
                {LsIcons.Gear_svg_icon}
              </button>
            </div>

            {isProcessing && (
              <div className="mt-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
                </div>
                <p className="text-center mt-2">{Math.round(progress)}% Complete</p>
              </div>
            )}

            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-2">Expansion Values (in original pixels):</h3>
              <div className="grid grid-cols-2 gap-4">
                <p>Left: {calculateExpansion().left}</p>
                <p>Right: {calculateExpansion().right}</p>
                <p>Top: {calculateExpansion().top}</p>
                <p>Bottom: {calculateExpansion().bottom}</p>
              </div>
            </div>
          </div>
        )}

        {maskImage && controlImage && (
          <div className="mt-6 grid grid-cols-2 gap-4">
            <div>
              <h3 className="text-lg font-semibold mb-2">Mask Image:</h3>
              <img src={maskImage} alt="Mask" className="w-full rounded-lg shadow-md" />
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Control Image:</h3>
              <img src={controlImage} alt="Control" className="w-full rounded-lg shadow-md" />
            </div>
          </div>
        )}

        {outpaintedImage && (
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Outpainted Image:</h3>
            <ReactCompareImage
              leftImage={controlImage}  // 使用controlImage替代image.src
              rightImage={outpaintedImage}
              sliderPositionPercentage={0.5}
              onLeftImageLoad={() => handleImageLoad('left')}
              onRightImageLoad={() => handleImageLoad('right')}
            />
            {(!imagesLoaded.left || !imagesLoaded.right) && (
              <div className="flex justify-center items-center h-16 mt-4">
                <div className="loader"></div>
              </div>
            )}
            <button
              onClick={() => window.open(outpaintedImage, '_blank')}
              className="mt-2 inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Download Outpainted Image
            </button>
          </div>
        )}
      </div>

      <FalApiKeyDialog
        apiKey={apiKey}
        setApiKey={setApiKey}
        isOpen={isApiKeyDialogOpen}
        setIsOpen={setIsApiKeyDialogOpen}
      />
    </div>
  );
};

export default ImageOutpaint;
