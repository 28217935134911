import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FeatureCard from './FeatureCard';
import HomeBlogPreview from './HomeBlogPreview';
import SEO from './seo/SEO';
import ContentAdComponent from './ContentAdComponent';

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: 'Home' });

    const fetchFeatures = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CMS_API_BASE_URL}/landingfeatures`);
        const data = response.data.map(feature => ({
          ...feature,
          imageSrc: `${process.env.REACT_APP_CMS_API_BASE_URL}${feature.imageSrc.url}`
        }));
        setFeatures(data);
      } catch (error) {
        console.error('Error fetching features:', error);
      }
    };

    fetchFeatures();
  }, [location.pathname]);

  return (
      <>
        <SEO
            title="LLMStock: AI-Powered Stock Analysis, LLM Models & AIGC Tools Playground"
            description="Explore cutting-edge AI with LLMStock. Access LLM-powered stock analysis, experiment with advanced language models, create AI-generated content, and stay updated on AI and stock market trends."
            image="https://cms.llmstock.com/uploads/llama3_a34dc48f66.jpg"
        />
        <BackgroundWrapper>
          <GradientOverlay />
          <ContentWrapper>
            <LandingPage>
              <Hero>
                <motion.h1
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                  LLM Agent For Stocks And AIGC Playground
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                  Harness the power of advanced AI for deep market insights
                </motion.p>
                <CTAButton onClick={() => navigate('/chat-stock')}>
                  Start Your Free Trial
                </CTAButton>
              </Hero>

              <FeaturesSection>
                <SectionTitle>Key Features</SectionTitle>
                <FeatureGrid>
                  {features.map((feature) => (
                      <FeatureCard
                          key={feature.id}
                          title={feature.title}
                          description={feature.description}
                          imageSrc={feature.imageSrc}
                          linkTo={feature.linkTo}
                      />
                  ))}
                </FeatureGrid>
              </FeaturesSection>

              <BlogSection>
                <HomeBlogPreview />
              </BlogSection>
              <ContentAdComponent adSlot='1527445564' />
              <Footer>
                <p>&copy; 2024 LLMStock. All rights reserved.</p>
              </Footer>
            </LandingPage>
          </ContentWrapper>
        </BackgroundWrapper>
      </>
  );
};

// Styled components
const BackgroundWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(135deg, #f6f9fc 0%, #e9f2f9 100%);
  position: relative;
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at top left, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 60%),
  radial-gradient(circle at bottom right, rgba(230,240,255,0.2) 0%, rgba(230,240,255,0) 60%);
  pointer-events: none;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const LandingPage = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  color: #333333;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const Hero = styled.div`
  text-align: center;
  padding: 30px 0;

  h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
    color: #1a202c;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 40px;
    color: #4a5568;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 768px) {
    padding: 40px 0;

    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
`;

const CTAButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 15px 35px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(76, 175, 80, 0.2);

  &:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(76, 175, 80, 0.3);
  }

  @media (max-width: 768px) {
    padding: 12px 25px;
    font-size: 16px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
  color: #2d3748;
  font-weight: 600;
  letter-spacing: -0.5px;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 30px;
  }
`;

const FeaturesSection = styled.section`
  margin: 30px 0;

  @media (max-width: 768px) {
    margin: 60px 0;
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

const BlogSection = styled.section`
  margin: 30px 0;

  @media (max-width: 768px) {
    margin: 60px 0;
  }
`;

const Footer = styled.footer`
  text-align: center;
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid rgba(226, 232, 240, 0.5);
  color: #4a5568;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    margin-top: 60px;
    padding: 20px 0;
  }
`;

export default Home;