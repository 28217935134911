import { useRef } from 'react';
import OpenAI from "openai";
import APIChatDB from '../db/APIChatDb';
import { toast } from 'react-toastify';
import ReactGA from "react-ga4";

export const useChat = ({ sessionId, baseurl, model, apiKey, maxTokens, temperature, messages, setMessages, setUserInput, contextSize, userInput, withCloud }) => {
    const controller = useRef(new AbortController());

    const addMessage = async (message) => {
        await APIChatDB.messages.add(message);
    };

    const removeLastMessage = async () => {
        const lastMessage = messages[messages.length - 1];
        if (lastMessage && lastMessage.role === 'assistant') {
            await APIChatDB.messages
                .where({ sessionId, role: 'assistant', content: lastMessage.content })
                .delete();
            setMessages(prevMessages => prevMessages.slice(0, -1));
        }
    };

    const handleSendMessage = async (userInput) => {
        if (!userInput.trim()) return;

        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatSendMessage'
        });

        const userMessage = { sessionId, role: 'user', content: userInput };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        await addMessage(userMessage);
        setUserInput('');

        const messagePairs = Math.floor(contextSize / 2);
        const historicalMessages = messages.slice(-(messagePairs * 2));

        const messageArray = [...historicalMessages, userMessage].map(msg => ({
            role: msg.role,
            content: msg.content
        }));

        let baseURL = baseurl;
        if (withCloud) {
            baseURL = `${window.location.origin}/api/chatproxy?base_url=${encodeURIComponent(baseurl)}`;
        }

        const openai = new OpenAI({
            baseURL: baseURL,
            apiKey: apiKey,
            defaultHeaders: {
                "HTTP-Referer": "llmstock.com",
                "X-Title": "llmstock",
            },
            dangerouslyAllowBrowser: true,
            fetch: (url, init) => {
                // 移除 x-stainless-retry-count 头部
                if (init.headers) {
                    const headers = new Headers(init.headers);
                    headers.delete('x-stainless-retry-count');
                    headers.delete('x-stainless-runtime');
                    headers.delete('x-stainless-runtime-version');
                    init.headers = headers;
                }
                return fetch(url, init);
            }
        });

        controller.current = new AbortController();
        const signal = controller.current.signal;

        try {
            const stream = await openai.chat.completions.create({
                model: model,
                messages: messageArray,
                max_tokens: parseInt(maxTokens, 10),
                temperature: parseFloat(temperature),
                stream: true
            }, { signal });

            let aiResponseContent = '';
            for await (const chunk of stream) {
                const content = chunk.choices[0]?.delta?.content || "";
                aiResponseContent += content;
                setMessages(prevMessages => {
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    if (lastMessage && lastMessage.role === 'assistant' && !lastMessage.final) {
                        return [
                            ...prevMessages.slice(0, -1),
                            { ...lastMessage, content: aiResponseContent }
                        ];
                    } else {
                        return [
                            ...prevMessages,
                            { sessionId, role: 'assistant', content: aiResponseContent, final: false }
                        ];
                    }
                });
            }

            setMessages(prevMessages => {
                const lastMessage = prevMessages[prevMessages.length - 1];
                if (lastMessage && lastMessage.role === 'assistant') {
                    return [
                        ...prevMessages.slice(0, -1),
                        { ...lastMessage, final: true }
                    ];
                } else {
                    return prevMessages;
                }
            });

            const aiMessage = { sessionId, role: 'assistant', content: aiResponseContent, final: true };
            await addMessage(aiMessage);
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Chat aborted');
            } else {
                console.error('Error during chat:', error);
            }
        }
    };

    const handleRegenerate = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatRegenerateMessage'
        });
        await removeLastMessage();
        const lastUserMessage = messages.slice().reverse().find(msg => msg.role === 'user');
        if (lastUserMessage) {
            await handleSendMessage(lastUserMessage.content);
        }
    };

    const handleStopChat = () => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatStopChat'
        });
        if (controller.current) {
            controller.current.abort();
        }
    };

    const handleClearChat = async () => {
        await APIChatDB.messages.where('sessionId').equals(sessionId).delete();
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatClearChat'
        });
        setMessages([]);
    };

    const handleCopyMessage = (content) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatCopyMessage'
        });
        navigator.clipboard.writeText(content).then(() => {
            toast.success('Message copied to clipboard!');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    const handleCopyCode = (code) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked APIChatCopyCode'
        });
        navigator.clipboard.writeText(code).then(() => {
            toast.success('Code copied to clipboard!');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    return {
        handleSendMessage,
        handleRegenerate,
        handleStopChat,
        handleClearChat,
        handleCopyMessage,
        handleCopyCode
    };
};
