// APITemplates.js
import axios from 'axios';
import React from 'react';

let APITemplates = [];

const fetchAPITemplates = async () => {
    try {
        // 使用 Strapi 的排序参数
        const response = await axios.get(`${process.env.REACT_APP_CMS_API_BASE_URL}/playgroundapis?_sort=priority:DESC`);
        APITemplates = response.data
            .sort((a, b) => b.priority - a.priority) // 再次在前端排序，以确保顺序正确
            .map(template => ({
                name: template.name,
                url: template.url,
                icon: <img
                    src={template.icon}
                    alt={template.alt}
                    className="h-12 w-12 transform transition duration-300 hover:scale-110"
                />,
                priority: template.priority
            }));
    } catch (err) {
        console.error('Error fetching API templates:', err);
    }
};

fetchAPITemplates();

export const getAPITemplates = async () => {
    if (APITemplates.length === 0) {
        await fetchAPITemplates();
    }
    return APITemplates;
};

export default APITemplates;
