import React, { useEffect, useRef } from 'react';

const FeedAdComponent = ({ index = 0 }) => {
  const adRef = useRef(null);

  const adSlots = [
    '7813953636',
    '2370100392',
    '8756940574',
    '5511398939',
    '1172568790',
    '2485650468'
  ];

  useEffect(() => {
    let observer;
    let didInitAd = false;

    const initializeAds = () => {
      if (adRef.current && !adRef.current.hasAttribute('data-ad-status')) {
        // console.log(`Initializing Adsense for slot: ${adSlots[index % adSlots.length]}`);
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
          adRef.current.setAttribute('data-ad-status', 'done');
          didInitAd = true;
        } catch (e) {
          // console.error('Adsense initialization error', e);
        }
      }
    };

    const onView = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !didInitAd) {
          initializeAds();
        }
      });
    };

    if (window.IntersectionObserver) {
      observer = new IntersectionObserver(onView);
      if (adRef.current) observer.observe(adRef.current);
    } else {
      // Fallback if IntersectionObserver is not supported
      initializeAds();
    }

    let script = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6030831640499533"]');
    if (!script) {
      script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6030831640499533';
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);

      script.onload = initializeAds;
    } else {
      // console.log("Adsbygoogle script already exists.");
    }

    return () => {
      if (observer && adRef.current) {
        observer.unobserve(adRef.current);
      }
    };
  }, [index]);

  return (
    <div
      ref={adRef}
      className="ad-container"
      style={{
        width: '100%',
        minWidth: '250px',
        height: '100%',
        margin: '0px 0px',
      }}
    >
      <ins
        className="adsbygoogle"
        style={{ display: 'block', width: '100%', height: 'auto' }}
        data-ad-client="ca-pub-6030831640499533"
        data-ad-slot={adSlots[index % adSlots.length]}
        data-ad-format="fluid"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default FeedAdComponent;
