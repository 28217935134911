// ShareButtons.js
import React from 'react';
// Bug 修复：修改导入语句为 JavaScript 规范的导入语句
import "./ShareButtons.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  XIcon,
  FacebookShareCount,
  RedditShareCount,
} from 'react-share';

const ShareButtons = ({ url, title, description }) => {
  return (
    <div className="share-buttons-container">
      <div className="share-button-wrapper">
        <FacebookShareButton
          url={url}
          quote={title}
          className="share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <div className="share-count">
          <FacebookShareCount url={url}>
            {count => count}
          </FacebookShareCount>
        </div>
      </div>

      <div className="share-button-wrapper">
        <TwitterShareButton
          url={url}
          title={title}
          className="share-button"
        >
          <XIcon size={32} round />
        </TwitterShareButton>
      </div>

      <div className="share-button-wrapper">
        <LinkedinShareButton 
          url={url} 
          title={title}
          summary={description}
          source="llmstock.com"
          className="share-button"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <div className="share-button-wrapper">
        <RedditShareButton
          url={url}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="share-button"
        >
          <RedditIcon size={32} round />
        </RedditShareButton>
        <div className="share-count">
          <RedditShareCount url={url} />
        </div>
      </div>
    </div>
  );
};

export default ShareButtons;
