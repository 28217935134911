import React, { useEffect, useRef, useCallback, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import CodeCopyBtn from './codeCopyBtn';
import mermaid from 'mermaid';
import "../../css/mermaid.css";
import { LsIcons } from './LsIcons';
import "../../css/post-detail.css";
import "../../css/CodePre.css";

const CodePre = ({ children }) => {
    const mermaidRef = useRef(null);
    const [mermaidSvg, setMermaidSvg] = useState('');
    const [error, setError] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [scale, setScale] = useState(1);
    const [isFullscreen, setIsFullscreen] = useState(false);
    let code = '';
    let language = '';
    if (children && children.props && children.props.children) {
        code = children.props.children;
        const match = /language-(\w+)/.exec(children.props.className || '');
        language = match ? match[1] : '';
    }

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    const renderMermaidDiagram = useCallback(() => {
        if (language === 'mermaid' && mermaidRef.current) {
            const id = `mermaid-${Math.random().toString(36).substr(2, 9)}`;
            mermaid.render(id, code)
                .then(({ svg }) => {
                    setMermaidSvg(svg);
                    setError(null);
                })
                .catch(err => {
                    console.error('Mermaid rendering failed:', err);
                    setError(err.message);
                });
        }
    }, [language, code]);

    useEffect(() => {
        mermaid.initialize({ startOnLoad: false, theme: 'default' });
    }, []);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            renderMermaidDiagram();
        }, 500);

        return () => clearTimeout(debounceTimer);
    }, [code, renderMermaidDiagram]);

    const handleCopyCode = () => {
        navigator.clipboard.writeText(code).then(() => {
            console.log('Code copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy code: ', err);
        });
    };

    const handleZoomIn = () => {
        setScale(prevScale => Math.min(prevScale + 0.1, 2));
    };

    const handleZoomOut = () => {
        setScale(prevScale => Math.max(prevScale - 0.1, 0.5));
    };

    const handleResetZoom = () => {
        setScale(1);
    };

    if (language === 'mermaid') {
        return (
            <div className="codepre-mermaid-container">
                <div className="codepre-button-container">
                    <span className="codepre-copy-button" onClick={handleCopyCode}>{LsIcons.Chat_copy_svg_icon} {language}</span>
                </div>
                <div ref={mermaidRef} className="codepre-mermaid">
                    {mermaidSvg ? (
                        <div dangerouslySetInnerHTML={{ __html: mermaidSvg }} />
                    ) : (
                        <pre className="codepre-custom-pre">{code}</pre>
                    )}
                </div>
                {error && <div className="codepre-error">Error rendering Mermaid: {error}</div>}
            </div>
        );
    }

    return (
        <div className="codepre-container">
            <div className="codepre-button-container">
                <CodeCopyBtn code={code} />
                <span className="codepre-language">{language}</span>
                {language === 'html' && (
                    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                        <Dialog.Trigger asChild>
                            <button className="codepre-render-btn">Render HTML</button>
                        </Dialog.Trigger>
                        <Dialog.Portal>
                            <Dialog.Overlay className="codepre-dialog-overlay" />
                            <Dialog.Content
                                className={`codepre-dialog-content ${isFullscreen ? 'fullscreen' : ''}`}
                            >
                                <div className="codepre-iframe-wrapper">
                                    <div className="codepre-iframe-container" style={{ transform: `scale(${scale})` }}>
                                        <iframe
                                            srcDoc={code}
                                            className="codepre-iframe"
                                            sandbox="allow-scripts"
                                            title="Rendered HTML"
                                        />
                                    </div>
                                </div>
                                <div className="codepre-controls">
                                    <button onClick={handleZoomIn} className="codepre-icon-btn">{LsIcons.Dashboards_add_svg_icon}</button>
                                    <button onClick={handleZoomOut} className="codepre-icon-btn">{LsIcons.SubMenu_remove_svg_icon}</button>
                                    <button onClick={handleResetZoom} className="codepre-icon-btn">{LsIcons.Rest_svg_icon}</button>
                                    <button onClick={toggleFullscreen} className="codepre-icon-btn">
                                        {isFullscreen ? LsIcons.Exit_full_screen_icon : LsIcons.Full_screen_icon}
                                    </button>
                                </div>
                                <Dialog.Close asChild>
                                    <button className="codepre-icon-btn codepre-close-btn" aria-label="Close">
                                        {LsIcons.Close_icon}
                                    </button>
                                </Dialog.Close>
                            </Dialog.Content>
                        </Dialog.Portal>
                    </Dialog.Root>
                )}
            </div>
            <pre className="codepre-custom-pre">
                {children}
            </pre>
        </div>
    );
};

export default CodePre;
